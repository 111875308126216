.capitalize {
  text-transform: capitalize;
}

input {
  border-radius: '6px' !important;
}

@media (max-width: 1600px) {
  .custom-w-full {
    width: 60%;
  }
}

@media (min-width: 0) and (max-width: 1300px) {
  .custom-w-full {
    width: 76%;
  }
}

@media (min-width: 0) and (max-width: 1100px) {
  .custom-w-full {
    width: 100%;
  }
}

//media
@media screen and (max-width: 1024px) {
  .hidden-mobile-tablet {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .hidden-mobile {
    display: none;
  }

  .w-1\/2 {
    width: 100%;
  }
}

/*Toast*/
.Toastify__toast-icon {
  width: 24px !important;
}

.Toastify__close-button--light {
  color: #111827 !important;
  opacity: 1 !important;
  align-self: center !important;
}

.Toastify__toast {
  border-radius: 10px !important;
  padding: 0 15px !important;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.05) !important;
}

.Toastify__toast-container {
  padding: 0 !important;
}

.Toastify__toast-theme--light {
  color: #111827 !important;
  font-size: 14px !important;
}

.Toastify__toast-body {
  padding: 0 !important;
}

//
.break-word {
  word-break: break-word;
}

.border-error {
  input {
    border: 1px solid rgb(220, 38, 38, .5) !important;

    &:focus {
      --tw-border-opacity: 1;
      border: solid 2px rgb(240 82 82/var(--tw-border-opacity)) !important;
      box-shadow: unset !important;
    }
  }

}

.border-error-select {
  .css-13cymwt-control {
    border: 1px solid rgb(220, 38, 38, .5) !important;

    :focus {
      --tw-border-opacity: 1 !important;
      border: solid 2px rgb(240 82 82/var(--tw-border-opacity)) !important;
      box-shadow: unset !important;
    }
  }
}

.react-select {
  width: 100%;
  border-color: rgba(132, 116, 106, 0.2) !important;

  &.error {
    .react-select__control {
      border-color: red !important;
      box-shadow: none !important;

      &:hover {
        border-color: red !important;
      }

      &:focus-within {
        border-width: 2px;
        border-color: red !important;
      }
    }
  }

  &__control {
    border-width: 1px;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: #F9FAFB;
  }

  &__value-container {
    height: inherit;
    padding: 0.625rem;
    border: none;
    color: #2B5873;
    align-content: center;

    &:focus-within .react-select__placeholder {
      display: none;
    }

    &--is-multi {
      display: flex;
      flex-wrap: nowrap;
    }

    .react-select__input-container {
      .react-select__input {
        &:focus {
          box-shadow: none;
        }
      }
    }

  }

  &__single-value {
    color: #2B5873;
  }

  &__indicator-separator {
    display: none;
  }

  &__indicator {
    display: flex;
    color: #2B5873;

    svg {
      cursor: pointer;

      &:hover {
        border-radius: 5px;
        background-color: #e5e7eb
      }
    }
  }

  &__placeholder {
    color: #2B5873;
  }

  &__menu {
    border-width: none;
    border-radius: 0.5rem;
    z-index: 10;

    &-list {
      border-width: none;
      border-radius: 0.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      background-color: #F9FAFB;
      color: #2B5873;
    }
  }

  &__option {
    color: #2B5873;
  }

  &__multi-value__label {
    color: #2B5873;
  }

}

.react-select-custom {
  width: 100%;
  border-color: rgba(132, 116, 106, 0.2) !important;

  &.error {
    .react-select-custom__control {
      border-color: red !important;
      box-shadow: none;

      &:hover {
        border-color: red !important;
      }

      &:focus-within {
        border-width: 2px;
        border-color: red !important;
      }
    }
  }

  &__control {
    border-width: 1px;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: #F9FAFB;
  }

  &__value-container {
    height: inherit;
    padding: 0.625rem;
    border: none;
    color: #2B5873;
    align-content: center;

    &:focus-within .react-select-custom__placeholder {
      display: none;
    }

    &--is-multi {
      display: flex;
      flex-wrap: nowrap;
    }

    .react-select-custom__input-container {
      .react-select-custom__input {
        &:focus {
          box-shadow: none;
        }
      }
    }

  }

  &__single-value {
    color: #2B5873;
  }

  &__indicator-separator {
    display: none;
  }

  &__indicator {
    display: flex;
    color: #2B5873;

    svg {
      cursor: pointer;

      &:hover {
        border-radius: 5px;
        background-color: #e5e7eb
      }
    }
  }

  &__placeholder {
    color: #2B5873;
  }

  &__menu {
    border-width: none;
    border-radius: 0.5rem;

    &-list {
      border-width: none;
      border-radius: 0.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      background-color: #F9FAFB;
      color: #2B5873;
    }
  }

  &__option {
    color: #2B5873;
  }

  &__multi-value__label {
    color: #2B5873;
  }

}

input {
  &:disabled {
    opacity: 50% !important;
  }
}

.action-three-dots {
  width: 34px;
  height: 30px;
  border-radius: 3px;
}


.dropdown-three-dots {

  >div {
    position: absolute !important;
    z-index: 10;
  }

  .dropdown {
    position: absolute !important;
    z-index: 10 !important;
  }

  >button {
    width: 34px !important;
    height: 34px !important;
    border-radius: 3px !important;
    box-shadow: unset !important;


    span {
      padding: 0 0 !important;

      div {
        svg {
          color: #301400
        }
      }
    }
  }

  ul {
    right: 0;
  }
}

td {
  .copy-icon {
    visibility: hidden;
  }

  &:hover {
    .copy-icon {
      visibility: visible;
    }
  }
}

.icon-sort {
  visibility: hidden;
}

th {
  &:hover {
    .icon-sort {
      visibility: visible;
    }
  }
}

.invitation-sent {
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 5px 4px;
  line-height: 10px;
  color: white;
  display: flex;
  align-items: center;
  width: 97px;
}

:focus-visible {
  outline: none !important;
}

button:focus {
  box-shadow: none !important;
  outline: none !important;
}

.progress-bar {
  // width: 70%;
  height: 30px;
  border-radius: 6px;
  background-color: white;
  background-size: cover;
  background-image: url("../assets/image/jpg/progress.png");

  >div {
    border-radius: 6px 0px 0px 6px;
    height: 30px;
    background-color: rgba(57, 109, 124, 1);

    >span {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      padding-right: 5px;
      font-size: 12px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
    }
  }
}


canvas {
  .chart-container {
    display: flex;
    justify-content: flex-start;
  }
}

.react-player {
  .react-player__preview {
    border-radius: 0.25rem;
  }
}

.text-header {
  font-family: "Montserrat", sans-serif !important;
}