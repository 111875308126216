.select-custom {
  .react-select__menu-list {
    background-color: white;
  }

  &.select-multi .react-select__menu-list {
    background-color: white;
    padding-bottom: 0px;
    padding: 10px;
  }

  .react-select__menu {
    background-color: white;
    border-radius: 0px 0px 10px 10px;
    margin-top: 0px !important;
    padding-bottom: 10px;
    padding-top: 10px;
    z-index: 10;
  }

  .react-select__option {
    background-color: white;
    color: black;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    padding: 10px 10px;
    width: auto;
  }

  &.select-multi .react-select__option {
    font-size: 12px;
    float: left;
    padding: 3px 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid rgba(132, 116, 106, 0.20);
  }

  .react-select__option--is-focused {
    background: #f4f8f9 !important;
  }

  .react-select__option--is-selected {
    background: rgba(227, 249, 253, 1) !important;
    color: black;
  }

  .react-select__multi-value__label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #301400;
  }

  .react-select__multi-value__remove {
    color: #84746A;
  }

  .react-select__placeholder {
    color: rgba(107, 114, 128, 1);
  }

  .react-select__value-container {
    min-height: 46px;
    max-height: 46px;
  }

  .holder {
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    color: rgba(107, 114, 128, 1);
    left: 5px;
    top: 24px;
    transform: translateY(-50%);
    transition: all 0.25s ease;
    pointer-events: none;
    background: none;
    padding: 0 5px;
    z-index: 1;
  }

  .label {
    background: white;
    border-radius: 6px;
    left: 10px;
    top: 0;
    font-size: 12px;
  }

  .react-select__control {
    background: none !important;
    border-radius: 6px;
    border: 1px solid rgba(132, 116, 106, 0.20);

    &--is-focused {
      box-shadow: 0 0 0 1px rgba(107, 114, 128, 1);
      border-color: rgba(107, 114, 128, 0) !important;
    }
  }
}