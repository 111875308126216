.more-action {
    cursor: pointer;

    .icon[aria-expanded="true"] {
        background-color: #ededed !important;
        border-radius: 2px !important;
    }

    svg {
        color: rgba(48, 20, 0, 1);
    }

    .more-action-dropdown {
        border-radius: 0px 0px 8px 8px;
        border: 1px solid #f9f5f5;
        box-shadow: 0px 2px 4px -2px #0000000D;
        box-shadow: 0px 4px 6px -1px #0000001A;
        min-width: 150px !important;
        padding-bottom: 10px;
        padding-top: 10px;
    }
}