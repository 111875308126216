.dashboard {
    .exercises-container {
        height: calc(100vh - 170px);
        padding-right: 10px;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.6);
        border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
        background: white
    }
}

@media (max-width: 1230px) {
    .dashboard {
        .dashboard-container {
            display: flex;
            flex-direction: column-reverse;

            .exercises-container {
                width: 100% !important;
                overflow: hidden;
                height: 100%;
            }

            .chart-container {
                width: 100% !important;
                margin-left: 0;
                margin-bottom: 20px;
            }
        }

    }
}

@media (max-width: 740px) {
    .dashboard {
        padding: 5px 15px;

        .exercises-container {
            padding-right: 0;
        }

        .calendar-container {
            .calendar {
                display: block;

                .month {
                    width: 100%;
                    margin-bottom: 20px;
                    text-align: center;
                }

                .date {
                    flex-wrap: wrap;
                    display: flex;
                    justify-content: center;
                }
            }
        }

        .chart-container {
            padding: 10px;
        }

        .font-small {
            font-size: 12px;
        }

        .height-small {
            height: 15px;
        }
    }
}