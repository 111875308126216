.paginate {
    bottom: 0;
    padding-bottom: 20px;
    height: 50px;

    .next-button {
        background-color: rgb(12, 192, 223, var(--tw-text-opacity));
        height: 100%;
        box-shadow: unset !important;
    }

    .previous-button {
        box-shadow: unset !important;
        height: 100%;
    }

    .paginate-icon {
        background-color: rgba(237, 237, 237, 1)
    }

    .paginate-input {
        background-color: rgba(132, 116, 106, 0.05)
    }

    input {
        box-shadow: unset !important;
        width: 67px;

        &:focus {
            border-color: unset !important;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@media screen and (max-width: 1400px) {
    .paginate {
        .paginate-button {
            width: 66% !important;
        }
    }
}

@media screen and (max-width: 700px) {
    .paginate {

        .paginate-button {
            justify-content: flex-start;

            .previous-button-container {
                margin-left: 0 !important;
            }

            .next-button,
            .previous-button {
                font-size: 0;

                svg {
                    margin-right: 0px;
                    margin-left: 0px;
                }
            }

        }

        .second-paginate {
            margin-right: 0px !important;
            padding-right: 0px !important;
        }
    }
}