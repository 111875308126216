.therapist-profile {
    height: 100%;

    .search-input {
        input {
            height: 34px !important;
        }
    }

    .therapist-detail-infor {
        input {
            height: 48px !important;
        }
    }


    .back-to-therapist {
        height: 80px;
    }

    .avatar-large {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 130px;
        background: #FF8A4C;
        border-radius: 50%;
        text-transform: uppercase;
        color: white;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
    }

    .therapist-summary {
        background-image: url("../../assets/image/jpg/bg-intro.jpg");

        .therapist-avatar-container {
            .therapist-avatar-edit {
                display: none;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                opacity: 0.7;
                background-color: black !important;

                &:hover {
                    display: flex !important;
                }
            }

            .therapist-avatar {
                width: 130px;
                height: 130px;

                img {
                    width: 100%;
                    height: 100%;
                }

            }

            .therapist-avatar:hover+.therapist-avatar-edit {
                display: flex !important;

            }
        }
    }

    .badge-active {
        background-color: rgba(0, 103, 131, 1);
        height: 24px;

        span {
            color: white;
            text-transform: uppercase;
            font-weight: 300;
        }
    }

    .badge-inactive {
        background-color: #F05252 !important;
        height: 24px;

        span {
            color: white;
            text-transform: uppercase;
            font-weight: 300;
        }
    }

    .therapist-infor {
        .tab-button {
            .tab-button {
                padding-left: 25px !important;
                @media screen and (max-width: 640px) {
                    padding-left: 15px !important;
                }
                button {
                    box-shadow: unset !important;
                    color: black;
                }

                button[aria-selected="true"] {
                    border-color: rgba(12, 192, 223, 1);
                    color: rgba(12, 192, 223, 1)
                }
            }

            >div:nth-child(2) {
                height: 100%;

                >div:nth-child(2) {
                    height: 100%;
                }
            }
        }

        .info-left-side {
            width: 60%;
            padding-right: 48px;
        }

        .info-right-side {
            width: 40%;
        }

        .therapist-label {
            min-width: 150px;
            max-width: 150px;
        }

        .avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            background: #FF8A4C;
            border-radius: 30px;
            text-transform: uppercase;
            color: white;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
        }

    }

    .dateOfBirth {
        >div>div {
            >div {
                right: 10px !important;
                left: unset !important;

                svg {
                    color: rgb(132, 116, 106);
                }
            }

            input {
                padding-left: 1rem;
                color: rgb(48, 20, 0);
            }
        }
    }

    .therapist-clinician-infor {
        .clinician-avatar {
            width: 100px;
            height: 100px;
            min-width: 100px;
            min-height: 100px;
        }
    }


}

@media screen and (max-width: 1400px) {
    .therapist-profile {

        .therapist-infor {

            .info-left-side,
            .info-right-side {
                width: 100%;
                padding-right: 0px;
            }
        }

    }
}

@media screen and (max-width: 1300px) {
    .therapist-profile {
        .therapist-clinician-infor {
            .clinician {
                display: block;

                .clinician-id {
                    margin-top: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .therapist-profile {
        .therapist-detail {
            .therapist-detail-infor {
                height: 250px;
                flex-direction: column !important;

                .therapist-detail-infor-side {
                    height: 50%;
                    width: 100%;
                }
            }
        }

    }


}

@media screen and (max-width: 640px) {
    .therapist-profile {
        .therapist-summary {
            .therapist-avatar-edit {
                .edit-icon {
                    width: 26px;
                    height: 26px;
                }

                width: 90px !important;
                height: 90px !important;
            }

            .name-status {
                display: block !important;

                .badge-active {
                    width: 60px;
                }

                .badge-inactive {
                    width: 72px;
                }

                p {
                    font-size: 1.25rem !important;
                }

            }

            .therapist-id {
                p {
                    font-size: 12px;
                }
            }

            .therapist-avatar {
                width: 90px !important;
                height: 90px !important;
            }
        }

        .therapist-infor {

            .therapist-label {
                min-width: 100px;
                max-width: 100px;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .therapist-profile {
        .therapist-clinician-infor {
            padding: 1rem;

            .clinician {
                padding: 1rem;

                .clinician-id {
                    display: block;
                }

                .copy-clinician-id {
                    min-width: 10px
                }
            }
        }
    }

}

@media screen and (max-width: 500px) {
    .therapist-profile {
        .therapist-infor {
            .therapist-detail-infor {
                padding: 1rem;

                .therapist-row {
                    display: block;
                    height: fit-content;
                    margin-bottom: 2px;

                    .therapist-label {
                        width: 100% !important;
                        max-width: 100%;
                        margin-bottom: 1px;
                    }

                    .therapist-value {
                        padding-left: 27px;
                        display: block;

                        .input-name {
                            margin-bottom: 10px;
                        }
                    }

                    .date-picker {
                        padding-left: 27px;
                        display: block;

                        .input-date {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }



    }
}