@import './mixins';

.main-layout-content {
  padding-top: 64px;
  padding-left: 240px;

  .selector-organization-mobile {
    display: none;
    background-color: rgba(132, 116, 106, 0.05);
  }
}

.sidebar {
  left: -1px;
  bottom: -1px;

  nav {
    height: unset !important;
  }

  padding-top: 56px;

  z-index: 1;

  .logo {
    padding-top: 4px;
    padding-bottom: 20px;
  }

  .px-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .py-4 {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }

  .select-org {
    display: flex;
    align-items: center;
    background-color: rgba(235, 245, 255, 1);

    .css-13cymwt-control,
    .css-t3ipsp-control {
      min-height: 55px;
      border-radius: 0;
      background-color: rgba(235, 245, 255, 1);
      border: 0;
    }

    .css-1u9des2-indicatorSeparator {
      width: 0;
    }

    &.one {
      height: 55px;
      padding-left: 10px;
    }

    .select-label {
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-size: 14px;
      font-weight: 500
    }
  }
}

//for mobile-tablet
@media screen and (max-width: 1023px) {

  .main-layout-content {
    padding-left: 60px;
  }
}

//for mobile
@media screen and (max-width: 640px) {
  .main-layout-content {
    padding-left: 0;
    padding-top: 0;

    .selector-organization-mobile {
      display: block;

      .selected-org-container {
        height: 100%;

        .site-admin {
          height: 100%;

          p {
            color: white !important;
          }

          .site-admin-logo-dark {
            display: block;
          }

          .site-admin-logo-light {
            display: none;
          }

          .site-admin-text {
            color: rgba(12, 192, 223, 1) !important;
          }
        }

        .select-org {
          border-radius: 0 !important;
          margin-left: unset;
          height: 100% !important;
          background-color: white !important;

          &:hover {
            background-color: white;
          }

          &.select-org-one {
            .select-label {

              .text-name,
              .text-member {
                color: rgba(12, 192, 223, 1) !important;
              }
            }
          }

          >div {
            width: 100%;
            height: 100% !important;
            // padding: 0 10px;
            border-radius: 0 !important;

            .css-13cymwt-control,
            .css-t3ipsp-control {
              min-height: unset;
              max-height: unset;
              height: 100%;
              border-radius: 0 !important;
              padding: 0 10px;

              &:hover,
              &:active,
              &:focus,
              &:focus-within {
                background-color: #396D7C !important;
              }

              .text-name,
              .text-member,
              .css-1xc3v61-indicatorContainer,
              .css-15lsz6c-indicatorContainer {
                color: white !important;
              }

              .text-name {
                font-size: 14px;
              }
            }

            .css-13cymwt-control {
              height: 100% !important;
              background-color: unset !important;
              border-radius: 0 !important;
              max-height: unset !important;

              .text-name,
              .text-member,
              .css-1xc3v61-indicatorContainer {
                color: white !important;
              }
            }
          }
        }
      }
    }
  }

  .sidebar {
    display: none;
  }

  .mt-content-mb {
    margin-top: 56px;
    // padding-bottom: 76px;
  }

  .mt-dashboard-mb {
    margin-top: 74px;
    overflow-y: unset !important;
    overflow-x: unset !important;
    box-shadow: unset !important;
    border: unset !important;
  }

  .nav-menu-mobile {
    z-index: 9999;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  }

  .show-dropdow-menu {
    transition: color 1s cubic-bezier(0.32, 0, 0.67, 0);
    transition: 0.5s;
    z-index: 3;
    position: fixed;
    background: white;
    width: 100%;
    height: 100%;
    padding: 10px;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

.avatar-size {
  width: 34px;
  height: 34px;
}

.h-modal > div[role="dialog"]{
  height: auto;
}