.user-detail-container {

    @media screen and (min-width: 640px) {
        .action-table {
            position: absolute;
            top: 0;
            right: 0;
            margin-top: -85px;
            z-index: 10;
            width: calc(100% - 380px);
        }
        .action-search {
            max-width: calc(100% - 155px);
        }
    }

    .avatar-large {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 130px;
        background: #FF8A4C;
        border-radius: 50%;
        text-transform: uppercase;
        color: white;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
    }

    .user-summary {
        background-image: url("../../assets/image/svg/userCoverImg.svg");

        .user-avatar-container {
            .user-avatar-edit {
                display: none;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                opacity: 0.7;
                background-color: black !important;

                &:hover {
                    display: flex !important;
                }
            }

            .user-avatar {
                width: 130px;
                height: 130px;

            }

            .user-avatar:hover+.user-avatar-edit {
                display: flex !important;

            }
        }
    }

    .badge-active {
        background-color: #0E9F6E !important;
        height: 24px;

        span {
            color: white;
            text-transform: uppercase;
            font-weight: 300;
        }
    }

    .badge-inactive {
        background-color: #F05252 !important;
        height: 24px;

        span {
            color: white;
            text-transform: uppercase;
            font-weight: 300;
        }
    }

    .user-infor {
        .tab-button {
            .tab-button {
                padding-left: 25px !important;

                button {
                    box-shadow: unset !important;
                }

                button[aria-selected="true"] {
                    border-color: #1C64F2;
                    color: #1C64F2
                }
            }
        }

        .user-detail-infor {
            height: 189px;
            background-color: #FFFFFF;
            margin: 10px 25px;
            border-radius: 10px;
            border: solid 1px #E5E7EB;

            .user-label {
                min-width: 150px;
            }

            .user-detail-infor-side {
                justify-content: space-around;
            }

            .edit-full-name {
                width: 100%;

                .input-name {
                    width: 35% !important;
                }

                .edit-full-name-form {
                    input {
                        border-radius: 0.25rem !important;
                    }

                    button {
                        box-shadow: unset !important;
                    }

                    .save-button {
                        &:hover {
                            --tw-bg-opacity: 1;
                            background-color: rgb(26 86 219 / var(--tw-bg-opacity));
                        }
                    }

                    .cancel-button {
                        &:hover {
                            --tw-bg-opacity: 1;
                            background-color: rgb(225 239 254 / var(--tw-bg-opacity));
                        }
                    }
                }
            }
        }

        .user-detail-org {
            background-color: #FFFFFF;
            margin: 10px 25px;
            border-radius: 10px;
            border: solid 1px #E5E7EB;
        }

        .avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            background: #FF8A4C;
            border-radius: 30px;
            text-transform: uppercase;
            color: white;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
        }

    }

    .organization {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 1400px) {
    .user-detail-container {
        .organization {
            display: flex !important;
            flex-wrap: wrap;

            >div {
                width: 31% !important;
            }
        }

    }
}

@media screen and (max-width: 1200px) {
    .user-detail-container {
        .user-detail {
            .user-detail-infor {
                height: 250px;
                flex-direction: column !important;

                .user-detail-infor-side {
                    height: 50%;
                    width: 100%;
                }
            }
        }

        .organization {
            display: flex !important;
            flex-wrap: wrap;

            >div {
                width: 48% !important;
            }
        }


        .tab-button .tab-button button {
            padding-left: 20px;
            padding-right: 20px;
        }
    }


}

@media screen and (max-width: 640px) {
    .user-detail-container {
        .user-summary {
            .user-avatar-edit {
                .edit-icon {
                    width: 26px;
                    height: 26px;
                }

                width: 90px !important;
                height: 90px !important;
            }

            .name-status {
                display: block !important;

                .badge-active {
                    width: 60px;
                }

                .badge-inactive {
                    width: 72px;
                }

                p {
                    font-size: 1.25rem !important;
                }

            }

            .user-id {
                p {
                    font-size: 12px;
                }
            }

            .user-avatar {
                width: 90px !important;
                height: 90px !important;
            }
        }

        .user-detail {
            .user-detail-infor {
                height: max-content;

                .user-label {
                    min-width: 50%;
                    max-width: 50%;
                }

                .user-value {
                    min-width: 50%;
                    max-width: 50%;
                    word-break: break-all;
                }

                .edit-full-name {
                    width: 100%;

                    .edit-full-name-form {
                        display: block;

                        .input-name {
                            width: 100% !important;
                            margin-bottom: 10px;
                        }
                    }
                }

                .user-detail-infor-row {
                    margin-top: 20px;
                }
            }
        }

        .organization {
            display: flex !important;
            flex-wrap: wrap;

            >div {
                width: 100% !important;
            }

            .organization-item-infor {
                padding-left: 0 !important;
            }
        }
    }
}

@media screen and (max-width: 467px) {
    .user-detail-container {
        .user-detail {
            .user-detail-infor {
                height: max-content;
                flex-direction: column !important;
                justify-content: space-around;

                .user-detail-infor-side {
                    height: 50%;
                    width: 100%;

                    .user-detail-infor-row {
                        margin-top: 10px;

                        .user-name {
                            width: 80%;
                            margin-right: 0 !important;
                        }
                    }
                }
            }
        }

    }
}