.modal-subcribe {
    // .plan-currencies {
    //     margin-bottom: 16px;
    //     color: #111928;
    //     font-weight: 800;
    //     font-size: 48px;

    //     .plan-amount {
    //         font-size: 50px;
    //         font-weight: 700;
    //         color: black;
    //     }

    //     .plan-interval {
    //         font-size: 20px;
    //         font-weight: 400;
    //         color: #6B7280;
    //     }
    // }
    .subcribe-button {
        &:hover {
            background-color: #0CC0DF !important;
        }
    }
}