.add-exercise-template {
    .input-text {
        width: 100%;
    }

    .description {
        .input-text {
            height: 100%;
        }
    }
}

@media (max-width: 750px) {
    .add-exercise-template {
        .form {
            display: flex;
            flex-direction: column;

            .left-side {
                width: 100%;

                .file {
                    width: 70%;
                }
            }

            .right-side {
                padding-left: 0;
                margin-top: 3rem;
                width: 100%;
                flex-grow: 1;
            }
        }
    }
}

@media (max-width: 600px) {
    .add-exercise-template {
        .form {

            .left-side {
                width: 100%;

                .file {
                    width: 100%;
                }
            }
        }
    }

}