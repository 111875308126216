.exercise-pdf-item {
    border-radius: 20px;
    // border-top: 1px solid white;
    // border-left: 1px solid white;
    // border-right: 1px solid white;

    .bg-lightTertiary {
        background-color: rgba(236, 240, 242, 1);
    }

    .text-10 {
        font-size: 10px;
    }

    .exercise-content {
        background-color: rgba(249, 250, 251, 1);
    }

    page-break-inside: avoid;
    break-inside: avoid;
}