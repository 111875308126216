.modal-add-user {
    >div {
        height: unset !important;
    }

    input {
        border-radius: 6px !important;
    }

    .react-select__control {
        border-radius: 6px !important;
    }
}

@media screen and (max-height: 550px) {
    .modal-add-user {
        >div {
            overflow: auto;
            height: 100% !important;
        }

        ::-webkit-scrollbar {
            display: none;
        }
    }
}