@import '~flowbite';
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  background: #F9FAFB;
  font-family: "Open Sans", sans-serif;
}

header {
  font-family: "Montserrat", sans-serif;
}

.overflow-y {
  overflow-y: scroll;
}

.wrapper-content {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.p-50 {
  padding: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-20 {
  margin-bottom: 20px;
}

.w-screen {
  width: 435px;
}

.h-full-important {
  height: 100% !important;
}

.list-table {
  height: calc(100vh - 190px);
}

.h-32 {
  height: 8rem;
}

.h-34 {
  height: 34px
}

.w-34 {
  width: 34px;
}

.h-15 {
  height: 15px
}

.w-15 {
  width: 15px;
}

.w-640 {
  width: 640px;
}
.h-360 {
  height: 360px;
}

.pt-14 {
  padding-top: 3.5rem;
}

.hover\:block:hover {
  display: block;
}
.text-header {
  font-family: "Montserrat", sans-serif !important;
}

input {
  border-radius: '6px' !important;
}

@media (min-width: 640px) {
  .sm\:border-l-3 {
    border-left-width: 3px;
  }


}

@media (max-width: 640px) {
  .list-table {
    height: unset !important;
      min-height: calc(100vh - 282px);
  }
}

@media screen and (max-width: 1024px) and (min-width: 640px){
  .hidden-tablet {
      display: none !important;
  }
}