.banner {
    min-height: 180px;
    //background: url("../../assets/image/jpg/bg-intro.jpg") no-repeat;
    background: rgba(57,109,124,0.1);
    padding: 15px;
    background-size: 100% 100%;
    margin-left: -1px;

    .banner-context {
        z-index: 4;
    }

    .hover\:opacity-1:hover {
        opacity: 1;
    }


}

@media screen and (max-width: 640px) {
    .banner {
        // height: fit-content;
        // min-height: fit-content;
        min-height: unset;
        //background: linear-gradient(180deg, #ACE3F2 12.58%, #F0E8D9 85.27%);
        background: #eeeeee;
        // background-size: contain;
        padding-top: 15px;
        padding-bottom: 15px;
        text-overflow: ellipsis;
        position: relative;
        height: max-content;

        .banner-image {
            width: 40px;
            height: 40px;
        }

        .banner-context {
            flex-grow: 1;
            padding-left: 15px;
            font-size: 12px;
            width: 100%;

            .title {
                font-size: 20px;
            }

            .context {
                display: block;
            }
        }

        .avatar {
            width: 90px;
            height: 90px;
        }
    }
}

@media screen and (max-width: 640px) {
    .banner {
        .container {
            flex-direction: column;
            align-items: flex-start;

            .avatar {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            .banner-context {
                margin-top: 70px;
                padding-left: 0;
            }
        }
    }
}