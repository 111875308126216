.programs {
    .css-13cymwt-control {
        border-radius: 2px !important;
    }

    .css-1u9des2-indicatorSeparator {
        display: none;
    }

    .css-t3ipsp-control {
        border-radius: 2px !important;
        box-shadow: unset !important;

        border: 1px solid rgba(107, 114, 128) !important;

        &:hover {
            border: 1px solid rgba(107, 114, 128) !important;
        }
    }
}