.user-management {
    padding: 10px 30px !important;

    tr {
        &:hover {
            .action-three-dots {
                background-color: #E1EFFE !important;
            }
        }
    }

    @media screen and (max-width: 640px) {
        .not-user {
            margin-left: 15px;
            margin-right: 15px;
        }
    }
}