.input-text {
  input {
    height: 48px;

    &:focus {
      box-shadow: none !important;
      border: 1px solid rgba(132, 116, 106, 1);
    }
  }

  p {
    margin-top: 0.25rem;
  }

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea {
    height: 100%;

    &:focus {
      box-shadow: none !important;
      border: 1px solid rgba(132, 116, 106, 1);
    }
  }

  textarea {
    border-radius: 6 !important;
    resize: none;
    background-color: white;
  }

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .holder {
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    color: rgba(107, 114, 128, 1);
    left: 5px;
    top: 24px;
    transform: translateY(-50%);
    transition: all 0.25s ease;
    pointer-events: none;
    background: none;
    padding: 0 5px;
    z-index: 1;
  }

  .holder-textarea {
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    color: rgba(107, 114, 128, 1);
    left: 5px;
    transform: translateY(-50%);
    transition: all 0.25s ease;
    pointer-events: none;
    background: none;
    padding: 0 5px;
    z-index: 1;
    top: 20px;
  }

  .label {
    background: white;
    border-radius: 6px;
    left: 10px;
    top: 0;
    font-size: 12px;
  }

  .right-icon {
    svg {
      width: 45px;
      height: 25px;
    }

  }
}