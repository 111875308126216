  .exercise-row {
      .action-in-large-screen {
          //   display: block;
      }

      .action-in-small-screen {
          display: none;
      }
  }

  @media (max-width: 850px) {
      .exercise-row {
          .action-in-large-screen {
              display: none;
          }

          .action-in-small-screen {
              display: block;
          }
      }


  }

  @media (max-width: 650px) {
      .exercise-row {
          .week-days {
              display: none;
          }
      }
  }