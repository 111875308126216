.user-profile {
    .user-dropdown {
        .dropdown-item {
            &.bg-gray-100 {
                background-color: #5ba1b7 !important;
            }

            overflow: auto;
            width: max-content;
            border: solid 1px rgb(226 232 240);
            border-radius: 5px;
            position: absolute;
            background-color: rgb(255 255 255);
            margin-top: 5px;
            box-shadow: 1px;
            padding: 3px 0;
            top: 45px;
            right: 0;
            z-index: 100;
        }
    }

}