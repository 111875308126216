.my-messages {
    height: calc(100vh - 116px);

    .scroll-conversation {
        height: calc(100vh - 182px);
    }

    .max-w-72 {
        max-width: 288px;
    }

    @media screen and (min-width: 640px) {
        height: calc(100vh - 57px);


        .scroll-conversation {
            height: calc(100vh - 123px);
        }
    }

    @media screen and (min-width: 1024px) {
        height: calc(100vh - 120px);

        .scroll-conversation {
            height: calc(100vh - 186px);
        }
    }

}