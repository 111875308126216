//primary
.text-primary,
.hover\:text-primary:hover,
.focus\:text-primary:focus,
.checked\:text-primary:checked {
    --tw-text-opacity: 1;
    color: rgba(12, 192, 223, var(--tw-text-opacity));
}

.\!text-primary,
.\!hover\:text-primary:hover,
.\!focus\:text-primary:focus,
.\!checked\:text-primary:checked {
    --tw-text-opacity: 1;
    color: rgba(12, 192, 223, var(--tw-text-opacity)) !important;
}

.bg-primary,
.hover\:bg-primary:hover,
.focus\:bg-primary:focus,
.checked\:bg-primary:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(12, 192, 223, var(--tw-bg-opacity));
}

.\!bg-primary,
.\!hover\:bg-primary:hover,
.\!focus\:bg-primary:focus,
.\!checked\:bg-primary:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(12, 192, 223, var(--tw-bg-opacity)) !important;
}

.border-primary,
.hover\:border-primary:hover,
.focus\:border-primary:focus,
.checked\:border-primary:checked {
    --tw-border-opacity: 1;
    border-color: rgba(12, 192, 223, var(--tw-border-opacity));
}

.\!border-primary,
.\!hover\:border-primary:hover,
.\!focus\:border-primary:focus,
.\!checked\:border-primary:checked {
    --tw-border-opacity: 1;
    border-color: rgba(12, 192, 223, var(--tw-border-opacity)) !important;
}

.ring-primary,
.hover\:ring-primary:hover,
.focus\:ring-primary:focus,
.checked\:ring-primary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(12, 192, 223, var(--tw-ring-opacity));
}

.\!ring-primary,
.\!hover\:ring-primary:hover,
.\!focus\:ring-primary:focus,
.\!checked\:ring-primary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(12, 192, 223, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-primary,
    .sm\:hover\:text-primary:hover,
    .sm\:focus\:text-primary:focus,
    .sm\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgba(12, 192, 223, var(--tw-text-opacity));
    }

    .\!sm\:text-primary,
    .\!sm\:hover\:text-primary:hover,
    .\!sm\:focus\:text-primary:focus,
    .\!sm\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgba(12, 192, 223, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-primary,
    .sm\:hover\:bg-primary:hover,
    .sm\:focus\:bg-primary:focus,
    .sm\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(12, 192, 223, var(--tw-bg-opacity));
    }

    .\!sm\:bg-primary,
    .\!sm\:hover\:bg-primary:hover,
    .\!sm\:focus\:bg-primary:focus,
    .\!sm\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(12, 192, 223, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-primary,
    .sm\:hover\:border-primary:hover,
    .sm\:focus\:border-primary:focus,
    .sm\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(12, 192, 223, var(--tw-border-opacity));
    }

    .\!sm\:border-primary,
    .\!sm\:hover\:border-primary:hover,
    .\!sm\:focus\:border-primary:focus,
    .\!sm\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(12, 192, 223, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-primary,
    .sm\:hover\:ring-primary:hover,
    .sm\:focus\:ring-primary:focus,
    .sm\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(12, 192, 223, var(--tw-ring-opacity));
    }

    .\!sm\:ring-primary,
    .\!sm\:hover\:ring-primary:hover,
    .\!sm\:focus\:ring-primary:focus,
    .\!sm\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(12, 192, 223, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-primary,
    .md\:hover\:text-primary:hover,
    .md\:focus\:text-primary:focus,
    .md\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgba(12, 192, 223, var(--tw-text-opacity));
    }

    .\!md\:text-primary,
    .\!md\:hover\:text-primary:hover,
    .\!md\:focus\:text-primary:focus,
    .\!md\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgba(12, 192, 223, var(--tw-text-opacity)) !important;
    }

    .md\:bg-primary,
    .md\:hover\:bg-primary:hover,
    .md\:focus\:bg-primary:focus,
    .md\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(12, 192, 223, var(--tw-bg-opacity));
    }

    .\!md\:bg-primary,
    .\!md\:hover\:bg-primary:hover,
    .\!md\:focus\:bg-primary:focus,
    .\!md\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(12, 192, 223, var(--tw-bg-opacity)) !important;
    }

    .md\:border-primary,
    .md\:hover\:border-primary:hover,
    .md\:focus\:border-primary:focus,
    .md\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(12, 192, 223, var(--tw-border-opacity));
    }

    .\!md\:border-primary,
    .\!md\:hover\:border-primary:hover,
    .\!md\:focus\:border-primary:focus,
    .\!md\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(12, 192, 223, var(--tw-border-opacity)) !important;
    }

    .md\:ring-primary,
    .md\:hover\:ring-primary:hover,
    .md\:focus\:ring-primary:focus,
    .md\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(12, 192, 223, var(--tw-ring-opacity));
    }

    .\!md\:ring-primary,
    .\!md\:hover\:ring-primary:hover,
    .\!md\:focus\:ring-primary:focus,
    .\!md\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(12, 192, 223, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-primary,
    .lg\:hover\:text-primary:hover,
    .lg\:focus\:text-primary:focus,
    .lg\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgba(12, 192, 223, var(--tw-text-opacity));
    }

    .\!lg\:text-primary,
    .\!lg\:hover\:text-primary:hover,
    .\!lg\:focus\:text-primary:focus,
    .\!lg\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgba(12, 192, 223, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-primary,
    .lg\:hover\:bg-primary:hover,
    .lg\:focus\:bg-primary:focus,
    .lg\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(12, 192, 223, var(--tw-bg-opacity));
    }

    .\!lg\:bg-primary,
    .\!lg\:hover\:bg-primary:hover,
    .\!lg\:focus\:bg-primary:focus,
    .\!lg\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(12, 192, 223, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-primary,
    .lg\:hover\:border-primary:hover,
    .lg\:focus\:border-primary:focus,
    .lg\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(12, 192, 223, var(--tw-border-opacity));
    }

    .\!lg\:border-primary,
    .\!lg\:hover\:border-primary:hover,
    .\!lg\:focus\:border-primary:focus,
    .\!lg\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(12, 192, 223, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-primary,
    .lg\:hover\:ring-primary:hover,
    .lg\:focus\:ring-primary:focus,
    .lg\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(12, 192, 223, var(--tw-ring-opacity));
    }

    .\!lg\:ring-primary,
    .\!lg\:hover\:ring-primary:hover,
    .\!lg\:focus\:ring-primary:focus,
    .\!lg\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(12, 192, 223, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-primary,
    .xl\:hover\:text-primary:hover,
    .xl\:focus\:text-primary:focus,
    .xl\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgba(12, 192, 223, var(--tw-text-opacity));
    }

    .\!xl\:text-primary,
    .\!xl\:hover\:text-primary:hover,
    .\!xl\:focus\:text-primary:focus,
    .\!xl\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgba(12, 192, 223, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-primary,
    .xl\:hover\:bg-primary:hover,
    .xl\:focus\:bg-primary:focus,
    .xl\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(12, 192, 223, var(--tw-bg-opacity));
    }

    .\!xl\:bg-primary,
    .\!xl\:hover\:bg-primary:hover,
    .\!xl\:focus\:bg-primary:focus,
    .\!xl\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(12, 192, 223, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-primary,
    .xl\:hover\:border-primary:hover,
    .xl\:focus\:border-primary:focus,
    .xl\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(12, 192, 223, var(--tw-border-opacity));
    }

    .\!xl\:border-primary,
    .\!xl\:hover\:border-primary:hover,
    .\!xl\:focus\:border-primary:focus,
    .\!xl\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(12, 192, 223, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-primary,
    .xl\:hover\:ring-primary:hover,
    .xl\:focus\:ring-primary:focus,
    .xl\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(12, 192, 223, var(--tw-ring-opacity));
    }

    .\!xl\:ring-primary,
    .\!xl\:hover\:ring-primary:hover,
    .\!xl\:focus\:ring-primary:focus,
    .\!xl\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(12, 192, 223, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-primary,
    .\2xl\:hover\:text-primary:hover,
    .\2xl\:focus\:text-primary:focus,
    .\2xl\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgba(12, 192, 223, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-primary,
    .\!2xlsm\:hover\:text-primary:hover,
    .\!2xlsm\:focus\:text-primary:focus,
    .\!2xlsm\:checked\:text-primary:checked {
        --tw-text-opacity: 1;
        color: rgba(12, 192, 223, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-primary,
    .\2xl\:hover\:bg-primary:hover,
    .\2xl\:focus\:bg-primary:focus,
    .\2xl\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(12, 192, 223, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-primary,
    .\!2xlsm\:hover\:bg-primary:hover,
    .\!2xlsm\:focus\:bg-primary:focus,
    .\!2xlsm\:checked\:bg-primary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(12, 192, 223, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-primary,
    .\2xl\:hover\:border-primary:hover,
    .\2xl\:focus\:border-primary:focus,
    .\2xl\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(12, 192, 223, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-primary,
    .\!2xlsm\:hover\:border-primary:hover,
    .\!2xlsm\:focus\:border-primary:focus,
    .\!2xlsm\:checked\:border-primary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(12, 192, 223, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-primary,
    .\2xl\:hover\:ring-primary:hover,
    .\2xl\:focus\:ring-primary:focus,
    .\2xl\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(12, 192, 223, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-primary,
    .\!2xl\:hover\:ring-primary:hover,
    .\!2xl\:focus\:ring-primary:focus,
    .\!2xl\:checked\:ring-primary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(12, 192, 223, var(--tw-ring-opacity)) !important;
    }
}

//secondary
.text-secondary,
.hover\:text-secondary:hover,
.focus\:text-secondary:focus,
.checked\:text-secondary:checked {
    --tw-text-opacity: 1;
    color: rgba(0, 51, 51, var(--tw-text-opacity));
}

.\!text-secondary,
.\!hover\:text-secondary:hover,
.\!focus\:text-secondary:focus,
.\!checked\:text-secondary:checked {
    --tw-text-opacity: 1;
    color: rgba(0, 51, 51, var(--tw-text-opacity)) !important;
}

.bg-secondary,
.hover\:bg-secondary:hover,
.focus\:bg-secondary:focus,
.checked\:bg-secondary:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 51, 51, var(--tw-bg-opacity));
}

.\!bg-secondary,
.\!hover\:bg-secondary:hover,
.\!focus\:bg-secondary:focus,
.\!checked\:bg-secondary:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 51, 51, var(--tw-bg-opacity)) !important;
}

.border-secondary,
.hover\:border-secondary:hover,
.focus\:border-secondary:focus,
.checked\:border-secondary:checked {
    --tw-border-opacity: 1;
    border-color: rgba(0, 51, 51, var(--tw-border-opacity));
}

.\!border-secondary,
.\!hover\:border-secondary:hover,
.\!focus\:border-secondary:focus,
.\!checked\:border-secondary:checked {
    --tw-border-opacity: 1;
    border-color: rgba(0, 51, 51, var(--tw-border-opacity)) !important;
}

.ring-secondary,
.hover\:ring-secondary:hover,
.focus\:ring-secondary:focus,
.checked\:ring-secondary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 51, 51, var(--tw-ring-opacity));
}

.\!ring-secondary,
.\!hover\:ring-secondary:hover,
.\!focus\:ring-secondary:focus,
.\!checked\:ring-secondary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 51, 51, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-secondary,
    .sm\:hover\:text-secondary:hover,
    .sm\:focus\:text-secondary:focus,
    .sm\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgba(0, 51, 51, var(--tw-text-opacity));
    }

    .\!sm\:text-secondary,
    .\!sm\:hover\:text-secondary:hover,
    .\!sm\:focus\:text-secondary:focus,
    .\!sm\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgba(0, 51, 51, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-secondary,
    .sm\:hover\:bg-secondary:hover,
    .sm\:focus\:bg-secondary:focus,
    .sm\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(0, 51, 51, var(--tw-bg-opacity));
    }

    .\!sm\:bg-secondary,
    .\!sm\:hover\:bg-secondary:hover,
    .\!sm\:focus\:bg-secondary:focus,
    .\!sm\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(0, 51, 51, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-secondary,
    .sm\:hover\:border-secondary:hover,
    .sm\:focus\:border-secondary:focus,
    .sm\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(0, 51, 51, var(--tw-border-opacity));
    }

    .\!sm\:border-secondary,
    .\!sm\:hover\:border-secondary:hover,
    .\!sm\:focus\:border-secondary:focus,
    .\!sm\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(0, 51, 51, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-secondary,
    .sm\:hover\:ring-secondary:hover,
    .sm\:focus\:ring-secondary:focus,
    .sm\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(0, 51, 51, var(--tw-ring-opacity));
    }

    .\!sm\:ring-secondary,
    .\!sm\:hover\:ring-secondary:hover,
    .\!sm\:focus\:ring-secondary:focus,
    .\!sm\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(0, 51, 51, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-secondary,
    .md\:hover\:text-secondary:hover,
    .md\:focus\:text-secondary:focus,
    .md\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgba(0, 51, 51, var(--tw-text-opacity));
    }

    .\!md\:text-secondary,
    .\!md\:hover\:text-secondary:hover,
    .\!md\:focus\:text-secondary:focus,
    .\!md\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgba(0, 51, 51, var(--tw-text-opacity)) !important;
    }

    .md\:bg-secondary,
    .md\:hover\:bg-secondary:hover,
    .md\:focus\:bg-secondary:focus,
    .md\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(0, 51, 51, var(--tw-bg-opacity));
    }

    .\!md\:bg-secondary,
    .\!md\:hover\:bg-secondary:hover,
    .\!md\:focus\:bg-secondary:focus,
    .\!md\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(0, 51, 51, var(--tw-bg-opacity)) !important;
    }

    .md\:border-secondary,
    .md\:hover\:border-secondary:hover,
    .md\:focus\:border-secondary:focus,
    .md\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(0, 51, 51, var(--tw-border-opacity));
    }

    .\!md\:border-secondary,
    .\!md\:hover\:border-secondary:hover,
    .\!md\:focus\:border-secondary:focus,
    .\!md\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(0, 51, 51, var(--tw-border-opacity)) !important;
    }

    .md\:ring-secondary,
    .md\:hover\:ring-secondary:hover,
    .md\:focus\:ring-secondary:focus,
    .md\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(0, 51, 51, var(--tw-ring-opacity));
    }

    .\!md\:ring-secondary,
    .\!md\:hover\:ring-secondary:hover,
    .\!md\:focus\:ring-secondary:focus,
    .\!md\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(0, 51, 51, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-secondary,
    .lg\:hover\:text-secondary:hover,
    .lg\:focus\:text-secondary:focus,
    .lg\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgba(0, 51, 51, var(--tw-text-opacity));
    }

    .\!lg\:text-secondary,
    .\!lg\:hover\:text-secondary:hover,
    .\!lg\:focus\:text-secondary:focus,
    .\!lg\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgba(0, 51, 51, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-secondary,
    .lg\:hover\:bg-secondary:hover,
    .lg\:focus\:bg-secondary:focus,
    .lg\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(0, 51, 51, var(--tw-bg-opacity));
    }

    .\!lg\:bg-secondary,
    .\!lg\:hover\:bg-secondary:hover,
    .\!lg\:focus\:bg-secondary:focus,
    .\!lg\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(0, 51, 51, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-secondary,
    .lg\:hover\:border-secondary:hover,
    .lg\:focus\:border-secondary:focus,
    .lg\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(0, 51, 51, var(--tw-border-opacity));
    }

    .\!lg\:border-secondary,
    .\!lg\:hover\:border-secondary:hover,
    .\!lg\:focus\:border-secondary:focus,
    .\!lg\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(0, 51, 51, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-secondary,
    .lg\:hover\:ring-secondary:hover,
    .lg\:focus\:ring-secondary:focus,
    .lg\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(0, 51, 51, var(--tw-ring-opacity));
    }

    .\!lg\:ring-secondary,
    .\!lg\:hover\:ring-secondary:hover,
    .\!lg\:focus\:ring-secondary:focus,
    .\!lg\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(0, 51, 51, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-secondary,
    .xl\:hover\:text-secondary:hover,
    .xl\:focus\:text-secondary:focus,
    .xl\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgba(0, 51, 51, var(--tw-text-opacity));
    }

    .\!xl\:text-secondary,
    .\!xl\:hover\:text-secondary:hover,
    .\!xl\:focus\:text-secondary:focus,
    .\!xl\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgba(0, 51, 51, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-secondary,
    .xl\:hover\:bg-secondary:hover,
    .xl\:focus\:bg-secondary:focus,
    .xl\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(0, 51, 51, var(--tw-bg-opacity));
    }

    .\!xl\:bg-secondary,
    .\!xl\:hover\:bg-secondary:hover,
    .\!xl\:focus\:bg-secondary:focus,
    .\!xl\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(0, 51, 51, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-secondary,
    .xl\:hover\:border-secondary:hover,
    .xl\:focus\:border-secondary:focus,
    .xl\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(0, 51, 51, var(--tw-border-opacity));
    }

    .\!xl\:border-secondary,
    .\!xl\:hover\:border-secondary:hover,
    .\!xl\:focus\:border-secondary:focus,
    .\!xl\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(0, 51, 51, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-secondary,
    .xl\:hover\:ring-secondary:hover,
    .xl\:focus\:ring-secondary:focus,
    .xl\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(0, 51, 51, var(--tw-ring-opacity));
    }

    .\!xl\:ring-secondary,
    .\!xl\:hover\:ring-secondary:hover,
    .\!xl\:focus\:ring-secondary:focus,
    .\!xl\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(0, 51, 51, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-secondary,
    .\2xl\:hover\:text-secondary:hover,
    .\2xl\:focus\:text-secondary:focus,
    .\2xl\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgba(0, 51, 51, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-secondary,
    .\!2xlsm\:hover\:text-secondary:hover,
    .\!2xlsm\:focus\:text-secondary:focus,
    .\!2xlsm\:checked\:text-secondary:checked {
        --tw-text-opacity: 1;
        color: rgba(0, 51, 51, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-secondary,
    .\2xl\:hover\:bg-secondary:hover,
    .\2xl\:focus\:bg-secondary:focus,
    .\2xl\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(0, 51, 51, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-secondary,
    .\!2xlsm\:hover\:bg-secondary:hover,
    .\!2xlsm\:focus\:bg-secondary:focus,
    .\!2xlsm\:checked\:bg-secondary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(0, 51, 51, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-secondary,
    .\2xl\:hover\:border-secondary:hover,
    .\2xl\:focus\:border-secondary:focus,
    .\2xl\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(0, 51, 51, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-secondary,
    .\!2xlsm\:hover\:border-secondary:hover,
    .\!2xlsm\:focus\:border-secondary:focus,
    .\!2xlsm\:checked\:border-secondary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(0, 51, 51, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-secondary,
    .\2xl\:hover\:ring-secondary:hover,
    .\2xl\:focus\:ring-secondary:focus,
    .\2xl\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(0, 51, 51, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-secondary,
    .\!2xl\:hover\:ring-secondary:hover,
    .\!2xl\:focus\:ring-secondary:focus,
    .\!2xl\:checked\:ring-secondary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(0, 51, 51, var(--tw-ring-opacity)) !important;
    }
}

//tertiary
.text-tertiary,
.hover\:text-tertiary:hover,
.focus\:text-tertiary:focus,
.checked\:text-tertiary:checked {
    --tw-text-opacity: 1;
    color: rgba(57, 109, 124, var(--tw-text-opacity));
}

.\!text-tertiary,
.\!hover\:text-tertiary:hover,
.\!focus\:text-tertiary:focus,
.\!checked\:text-tertiary:checked {
    --tw-text-opacity: 1;
    color: rgba(57, 109, 124, var(--tw-text-opacity)) !important;
}

.bg-tertiary,
.hover\:bg-tertiary:hover,
.focus\:bg-tertiary:focus,
.checked\:bg-tertiary:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 109, 124, var(--tw-bg-opacity));
}

.\!bg-tertiary,
.\!hover\:bg-tertiary:hover,
.\!focus\:bg-tertiary:focus,
.\!checked\:bg-tertiary:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(57, 109, 124, var(--tw-bg-opacity)) !important;
}

.border-tertiary,
.hover\:border-tertiary:hover,
.focus\:border-tertiary:focus,
.checked\:border-tertiary:checked {
    --tw-border-opacity: 1;
    border-color: rgba(57, 109, 124, var(--tw-border-opacity));
}

.\!border-tertiary,
.\!hover\:border-tertiary:hover,
.\!focus\:border-tertiary:focus,
.\!checked\:border-tertiary:checked {
    --tw-border-opacity: 1;
    border-color: rgba(57, 109, 124, var(--tw-border-opacity)) !important;
}

.ring-tertiary,
.hover\:ring-tertiary:hover,
.focus\:ring-tertiary:focus,
.checked\:ring-tertiary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 109, 124, var(--tw-ring-opacity));
}

.\!ring-tertiary,
.\!hover\:ring-tertiary:hover,
.\!focus\:ring-tertiary:focus,
.\!checked\:ring-tertiary:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(57, 109, 124, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-tertiary,
    .sm\:hover\:text-tertiary:hover,
    .sm\:focus\:text-tertiary:focus,
    .sm\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgba(57, 109, 124, var(--tw-text-opacity));
    }

    .\!sm\:text-tertiary,
    .\!sm\:hover\:text-tertiary:hover,
    .\!sm\:focus\:text-tertiary:focus,
    .\!sm\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgba(57, 109, 124, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-tertiary,
    .sm\:hover\:bg-tertiary:hover,
    .sm\:focus\:bg-tertiary:focus,
    .sm\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(57, 109, 124, var(--tw-bg-opacity));
    }

    .\!sm\:bg-tertiary,
    .\!sm\:hover\:bg-tertiary:hover,
    .\!sm\:focus\:bg-tertiary:focus,
    .\!sm\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(57, 109, 124, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-tertiary,
    .sm\:hover\:border-tertiary:hover,
    .sm\:focus\:border-tertiary:focus,
    .sm\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(57, 109, 124, var(--tw-border-opacity));
    }

    .\!sm\:border-tertiary,
    .\!sm\:hover\:border-tertiary:hover,
    .\!sm\:focus\:border-tertiary:focus,
    .\!sm\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(57, 109, 124, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-tertiary,
    .sm\:hover\:ring-tertiary:hover,
    .sm\:focus\:ring-tertiary:focus,
    .sm\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(57, 109, 124, var(--tw-ring-opacity));
    }

    .\!sm\:ring-tertiary,
    .\!sm\:hover\:ring-tertiary:hover,
    .\!sm\:focus\:ring-tertiary:focus,
    .\!sm\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(57, 109, 124, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-tertiary,
    .md\:hover\:text-tertiary:hover,
    .md\:focus\:text-tertiary:focus,
    .md\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgba(57, 109, 124, var(--tw-text-opacity));
    }

    .\!md\:text-tertiary,
    .\!md\:hover\:text-tertiary:hover,
    .\!md\:focus\:text-tertiary:focus,
    .\!md\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgba(57, 109, 124, var(--tw-text-opacity)) !important;
    }

    .md\:bg-tertiary,
    .md\:hover\:bg-tertiary:hover,
    .md\:focus\:bg-tertiary:focus,
    .md\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(57, 109, 124, var(--tw-bg-opacity));
    }

    .\!md\:bg-tertiary,
    .\!md\:hover\:bg-tertiary:hover,
    .\!md\:focus\:bg-tertiary:focus,
    .\!md\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(57, 109, 124, var(--tw-bg-opacity)) !important;
    }

    .md\:border-tertiary,
    .md\:hover\:border-tertiary:hover,
    .md\:focus\:border-tertiary:focus,
    .md\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(57, 109, 124, var(--tw-border-opacity));
    }

    .\!md\:border-tertiary,
    .\!md\:hover\:border-tertiary:hover,
    .\!md\:focus\:border-tertiary:focus,
    .\!md\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(57, 109, 124, var(--tw-border-opacity)) !important;
    }

    .md\:ring-tertiary,
    .md\:hover\:ring-tertiary:hover,
    .md\:focus\:ring-tertiary:focus,
    .md\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(57, 109, 124, var(--tw-ring-opacity));
    }

    .\!md\:ring-tertiary,
    .\!md\:hover\:ring-tertiary:hover,
    .\!md\:focus\:ring-tertiary:focus,
    .\!md\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(57, 109, 124, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-tertiary,
    .lg\:hover\:text-tertiary:hover,
    .lg\:focus\:text-tertiary:focus,
    .lg\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgba(57, 109, 124, var(--tw-text-opacity));
    }

    .\!lg\:text-tertiary,
    .\!lg\:hover\:text-tertiary:hover,
    .\!lg\:focus\:text-tertiary:focus,
    .\!lg\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgba(57, 109, 124, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-tertiary,
    .lg\:hover\:bg-tertiary:hover,
    .lg\:focus\:bg-tertiary:focus,
    .lg\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(57, 109, 124, var(--tw-bg-opacity));
    }

    .\!lg\:bg-tertiary,
    .\!lg\:hover\:bg-tertiary:hover,
    .\!lg\:focus\:bg-tertiary:focus,
    .\!lg\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(57, 109, 124, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-tertiary,
    .lg\:hover\:border-tertiary:hover,
    .lg\:focus\:border-tertiary:focus,
    .lg\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(57, 109, 124, var(--tw-border-opacity));
    }

    .\!lg\:border-tertiary,
    .\!lg\:hover\:border-tertiary:hover,
    .\!lg\:focus\:border-tertiary:focus,
    .\!lg\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(57, 109, 124, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-tertiary,
    .lg\:hover\:ring-tertiary:hover,
    .lg\:focus\:ring-tertiary:focus,
    .lg\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(57, 109, 124, var(--tw-ring-opacity));
    }

    .\!lg\:ring-tertiary,
    .\!lg\:hover\:ring-tertiary:hover,
    .\!lg\:focus\:ring-tertiary:focus,
    .\!lg\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(57, 109, 124, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-tertiary,
    .xl\:hover\:text-tertiary:hover,
    .xl\:focus\:text-tertiary:focus,
    .xl\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgba(57, 109, 124, var(--tw-text-opacity));
    }

    .\!xl\:text-tertiary,
    .\!xl\:hover\:text-tertiary:hover,
    .\!xl\:focus\:text-tertiary:focus,
    .\!xl\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgba(57, 109, 124, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-tertiary,
    .xl\:hover\:bg-tertiary:hover,
    .xl\:focus\:bg-tertiary:focus,
    .xl\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(57, 109, 124, var(--tw-bg-opacity));
    }

    .\!xl\:bg-tertiary,
    .\!xl\:hover\:bg-tertiary:hover,
    .\!xl\:focus\:bg-tertiary:focus,
    .\!xl\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(57, 109, 124, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-tertiary,
    .xl\:hover\:border-tertiary:hover,
    .xl\:focus\:border-tertiary:focus,
    .xl\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(57, 109, 124, var(--tw-border-opacity));
    }

    .\!xl\:border-tertiary,
    .\!xl\:hover\:border-tertiary:hover,
    .\!xl\:focus\:border-tertiary:focus,
    .\!xl\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(57, 109, 124, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-tertiary,
    .xl\:hover\:ring-tertiary:hover,
    .xl\:focus\:ring-tertiary:focus,
    .xl\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(57, 109, 124, var(--tw-ring-opacity));
    }

    .\!xl\:ring-tertiary,
    .\!xl\:hover\:ring-tertiary:hover,
    .\!xl\:focus\:ring-tertiary:focus,
    .\!xl\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(57, 109, 124, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-tertiary,
    .\2xl\:hover\:text-tertiary:hover,
    .\2xl\:focus\:text-tertiary:focus,
    .\2xl\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgba(57, 109, 124, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-tertiary,
    .\!2xlsm\:hover\:text-tertiary:hover,
    .\!2xlsm\:focus\:text-tertiary:focus,
    .\!2xlsm\:checked\:text-tertiary:checked {
        --tw-text-opacity: 1;
        color: rgba(57, 109, 124, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-tertiary,
    .\2xl\:hover\:bg-tertiary:hover,
    .\2xl\:focus\:bg-tertiary:focus,
    .\2xl\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(57, 109, 124, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-tertiary,
    .\!2xlsm\:hover\:bg-tertiary:hover,
    .\!2xlsm\:focus\:bg-tertiary:focus,
    .\!2xlsm\:checked\:bg-tertiary:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(57, 109, 124, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-tertiary,
    .\2xl\:hover\:border-tertiary:hover,
    .\2xl\:focus\:border-tertiary:focus,
    .\2xl\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(57, 109, 124, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-tertiary,
    .\!2xlsm\:hover\:border-tertiary:hover,
    .\!2xlsm\:focus\:border-tertiary:focus,
    .\!2xlsm\:checked\:border-tertiary:checked {
        --tw-border-opacity: 1;
        border-color: rgba(57, 109, 124, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-tertiary,
    .\2xl\:hover\:ring-tertiary:hover,
    .\2xl\:focus\:ring-tertiary:focus,
    .\2xl\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(57, 109, 124, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-tertiary,
    .\!2xl\:hover\:ring-tertiary:hover,
    .\!2xl\:focus\:ring-tertiary:focus,
    .\!2xl\:checked\:ring-tertiary:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(57, 109, 124, var(--tw-ring-opacity)) !important;
    }
}

//gray
.text-gray,
.hover\:text-gray:hover,
.focus\:text-gray:focus,
.checked\:text-gray:checked {
    --tw-text-opacity: 1;
    color: rgba(237, 237, 237, var(--tw-text-opacity));
}

.\!text-gray,
.\!hover\:text-gray:hover,
.\!focus\:text-gray:focus,
.\!checked\:text-gray:checked {
    --tw-text-opacity: 1;
    color: rgba(237, 237, 237, var(--tw-text-opacity)) !important;
}

.bg-gray,
.hover\:bg-gray:hover,
.focus\:bg-gray:focus,
.checked\:bg-gray:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 237, 237, var(--tw-bg-opacity));
}

.\!bg-gray,
.\!hover\:bg-gray:hover,
.\!focus\:bg-gray:focus,
.\!checked\:bg-gray:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 237, 237, var(--tw-bg-opacity)) !important;
}

.border-gray,
.hover\:border-gray:hover,
.focus\:border-gray:focus,
.checked\:border-gray:checked {
    --tw-border-opacity: 1;
    border-color: rgba(237, 237, 237, var(--tw-border-opacity));
}

.\!border-gray,
.\!hover\:border-gray:hover,
.\!focus\:border-gray:focus,
.\!checked\:border-gray:checked {
    --tw-border-opacity: 1;
    border-color: rgba(237, 237, 237, var(--tw-border-opacity)) !important;
}

.ring-gray,
.hover\:ring-gray:hover,
.focus\:ring-gray:focus,
.checked\:ring-gray:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 237, 237, var(--tw-ring-opacity));
}

.\!ring-gray,
.\!hover\:ring-gray:hover,
.\!focus\:ring-gray:focus,
.\!checked\:ring-gray:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 237, 237, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-gray,
    .sm\:hover\:text-gray:hover,
    .sm\:focus\:text-gray:focus,
    .sm\:checked\:text-gray:checked {
        --tw-text-opacity: 1;
        color: rgba(237, 237, 237, var(--tw-text-opacity));
    }

    .\!sm\:text-gray,
    .\!sm\:hover\:text-gray:hover,
    .\!sm\:focus\:text-gray:focus,
    .\!sm\:checked\:text-gray:checked {
        --tw-text-opacity: 1;
        color: rgba(237, 237, 237, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-gray,
    .sm\:hover\:bg-gray:hover,
    .sm\:focus\:bg-gray:focus,
    .sm\:checked\:bg-gray:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(237, 237, 237, var(--tw-bg-opacity));
    }

    .\!sm\:bg-gray,
    .\!sm\:hover\:bg-gray:hover,
    .\!sm\:focus\:bg-gray:focus,
    .\!sm\:checked\:bg-gray:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(237, 237, 237, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-gray,
    .sm\:hover\:border-gray:hover,
    .sm\:focus\:border-gray:focus,
    .sm\:checked\:border-gray:checked {
        --tw-border-opacity: 1;
        border-color: rgba(237, 237, 237, var(--tw-border-opacity));
    }

    .\!sm\:border-gray,
    .\!sm\:hover\:border-gray:hover,
    .\!sm\:focus\:border-gray:focus,
    .\!sm\:checked\:border-gray:checked {
        --tw-border-opacity: 1;
        border-color: rgba(237, 237, 237, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-gray,
    .sm\:hover\:ring-gray:hover,
    .sm\:focus\:ring-gray:focus,
    .sm\:checked\:ring-gray:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(237, 237, 237, var(--tw-ring-opacity));
    }

    .\!sm\:ring-gray,
    .\!sm\:hover\:ring-gray:hover,
    .\!sm\:focus\:ring-gray:focus,
    .\!sm\:checked\:ring-gray:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(237, 237, 237, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-gray,
    .md\:hover\:text-gray:hover,
    .md\:focus\:text-gray:focus,
    .md\:checked\:text-gray:checked {
        --tw-text-opacity: 1;
        color: rgba(237, 237, 237, var(--tw-text-opacity));
    }

    .\!md\:text-gray,
    .\!md\:hover\:text-gray:hover,
    .\!md\:focus\:text-gray:focus,
    .\!md\:checked\:text-gray:checked {
        --tw-text-opacity: 1;
        color: rgba(237, 237, 237, var(--tw-text-opacity)) !important;
    }

    .md\:bg-gray,
    .md\:hover\:bg-gray:hover,
    .md\:focus\:bg-gray:focus,
    .md\:checked\:bg-gray:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(237, 237, 237, var(--tw-bg-opacity));
    }

    .\!md\:bg-gray,
    .\!md\:hover\:bg-gray:hover,
    .\!md\:focus\:bg-gray:focus,
    .\!md\:checked\:bg-gray:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(237, 237, 237, var(--tw-bg-opacity)) !important;
    }

    .md\:border-gray,
    .md\:hover\:border-gray:hover,
    .md\:focus\:border-gray:focus,
    .md\:checked\:border-gray:checked {
        --tw-border-opacity: 1;
        border-color: rgba(237, 237, 237, var(--tw-border-opacity));
    }

    .\!md\:border-gray,
    .\!md\:hover\:border-gray:hover,
    .\!md\:focus\:border-gray:focus,
    .\!md\:checked\:border-gray:checked {
        --tw-border-opacity: 1;
        border-color: rgba(237, 237, 237, var(--tw-border-opacity)) !important;
    }

    .md\:ring-gray,
    .md\:hover\:ring-gray:hover,
    .md\:focus\:ring-gray:focus,
    .md\:checked\:ring-gray:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(237, 237, 237, var(--tw-ring-opacity));
    }

    .\!md\:ring-gray,
    .\!md\:hover\:ring-gray:hover,
    .\!md\:focus\:ring-gray:focus,
    .\!md\:checked\:ring-gray:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(237, 237, 237, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-gray,
    .lg\:hover\:text-gray:hover,
    .lg\:focus\:text-gray:focus,
    .lg\:checked\:text-gray:checked {
        --tw-text-opacity: 1;
        color: rgba(237, 237, 237, var(--tw-text-opacity));
    }

    .\!lg\:text-gray,
    .\!lg\:hover\:text-gray:hover,
    .\!lg\:focus\:text-gray:focus,
    .\!lg\:checked\:text-gray:checked {
        --tw-text-opacity: 1;
        color: rgba(237, 237, 237, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-gray,
    .lg\:hover\:bg-gray:hover,
    .lg\:focus\:bg-gray:focus,
    .lg\:checked\:bg-gray:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(237, 237, 237, var(--tw-bg-opacity));
    }

    .\!lg\:bg-gray,
    .\!lg\:hover\:bg-gray:hover,
    .\!lg\:focus\:bg-gray:focus,
    .\!lg\:checked\:bg-gray:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(237, 237, 237, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-gray,
    .lg\:hover\:border-gray:hover,
    .lg\:focus\:border-gray:focus,
    .lg\:checked\:border-gray:checked {
        --tw-border-opacity: 1;
        border-color: rgba(237, 237, 237, var(--tw-border-opacity));
    }

    .\!lg\:border-gray,
    .\!lg\:hover\:border-gray:hover,
    .\!lg\:focus\:border-gray:focus,
    .\!lg\:checked\:border-gray:checked {
        --tw-border-opacity: 1;
        border-color: rgba(237, 237, 237, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-gray,
    .lg\:hover\:ring-gray:hover,
    .lg\:focus\:ring-gray:focus,
    .lg\:checked\:ring-gray:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(237, 237, 237, var(--tw-ring-opacity));
    }

    .\!lg\:ring-gray,
    .\!lg\:hover\:ring-gray:hover,
    .\!lg\:focus\:ring-gray:focus,
    .\!lg\:checked\:ring-gray:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(237, 237, 237, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-gray,
    .xl\:hover\:text-gray:hover,
    .xl\:focus\:text-gray:focus,
    .xl\:checked\:text-gray:checked {
        --tw-text-opacity: 1;
        color: rgba(237, 237, 237, var(--tw-text-opacity));
    }

    .\!xl\:text-gray,
    .\!xl\:hover\:text-gray:hover,
    .\!xl\:focus\:text-gray:focus,
    .\!xl\:checked\:text-gray:checked {
        --tw-text-opacity: 1;
        color: rgba(237, 237, 237, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-gray,
    .xl\:hover\:bg-gray:hover,
    .xl\:focus\:bg-gray:focus,
    .xl\:checked\:bg-gray:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(237, 237, 237, var(--tw-bg-opacity));
    }

    .\!xl\:bg-gray,
    .\!xl\:hover\:bg-gray:hover,
    .\!xl\:focus\:bg-gray:focus,
    .\!xl\:checked\:bg-gray:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(237, 237, 237, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-gray,
    .xl\:hover\:border-gray:hover,
    .xl\:focus\:border-gray:focus,
    .xl\:checked\:border-gray:checked {
        --tw-border-opacity: 1;
        border-color: rgba(237, 237, 237, var(--tw-border-opacity));
    }

    .\!xl\:border-gray,
    .\!xl\:hover\:border-gray:hover,
    .\!xl\:focus\:border-gray:focus,
    .\!xl\:checked\:border-gray:checked {
        --tw-border-opacity: 1;
        border-color: rgba(237, 237, 237, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-gray,
    .xl\:hover\:ring-gray:hover,
    .xl\:focus\:ring-gray:focus,
    .xl\:checked\:ring-gray:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(237, 237, 237, var(--tw-ring-opacity));
    }

    .\!xl\:ring-gray,
    .\!xl\:hover\:ring-gray:hover,
    .\!xl\:focus\:ring-gray:focus,
    .\!xl\:checked\:ring-gray:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(237, 237, 237, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-gray,
    .\2xl\:hover\:text-gray:hover,
    .\2xl\:focus\:text-gray:focus,
    .\2xl\:checked\:text-gray:checked {
        --tw-text-opacity: 1;
        color: rgba(237, 237, 237, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-gray,
    .\!2xlsm\:hover\:text-gray:hover,
    .\!2xlsm\:focus\:text-gray:focus,
    .\!2xlsm\:checked\:text-gray:checked {
        --tw-text-opacity: 1;
        color: rgba(237, 237, 237, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-gray,
    .\2xl\:hover\:bg-gray:hover,
    .\2xl\:focus\:bg-gray:focus,
    .\2xl\:checked\:bg-gray:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(237, 237, 237, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-gray,
    .\!2xlsm\:hover\:bg-gray:hover,
    .\!2xlsm\:focus\:bg-gray:focus,
    .\!2xlsm\:checked\:bg-gray:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(237, 237, 237, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-gray,
    .\2xl\:hover\:border-gray:hover,
    .\2xl\:focus\:border-gray:focus,
    .\2xl\:checked\:border-gray:checked {
        --tw-border-opacity: 1;
        border-color: rgba(237, 237, 237, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-gray,
    .\!2xlsm\:hover\:border-gray:hover,
    .\!2xlsm\:focus\:border-gray:focus,
    .\!2xlsm\:checked\:border-gray:checked {
        --tw-border-opacity: 1;
        border-color: rgba(237, 237, 237, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-gray,
    .\2xl\:hover\:ring-gray:hover,
    .\2xl\:focus\:ring-gray:focus,
    .\2xl\:checked\:ring-gray:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(237, 237, 237, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-gray,
    .\!2xl\:hover\:ring-gray:hover,
    .\!2xl\:focus\:ring-gray:focus,
    .\!2xl\:checked\:ring-gray:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(237, 237, 237, var(--tw-ring-opacity)) !important;
    }
}

//border
.text-border,
.hover\:text-border:hover,
.focus\:text-border:focus,
.checked\:text-border:checked {
    --tw-text-opacity: 1;
    color: rgba(236, 236, 236, var(--tw-text-opacity));
}

.\!text-border,
.\!hover\:text-border:hover,
.\!focus\:text-border:focus,
.\!checked\:text-border:checked {
    --tw-text-opacity: 1;
    color: rgba(236, 236, 236, var(--tw-text-opacity)) !important;
}

.bg-border,
.hover\:bg-border:hover,
.focus\:bg-border:focus,
.checked\:bg-border:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 236, 236, var(--tw-bg-opacity));
}

.\!bg-border,
.\!hover\:bg-border:hover,
.\!focus\:bg-border:focus,
.\!checked\:bg-border:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 236, 236, var(--tw-bg-opacity)) !important;
}

.border-border,
.hover\:border-border:hover,
.focus\:border-border:focus,
.checked\:border-border:checked {
    --tw-border-opacity: 1;
    border-color: rgba(236, 236, 236, var(--tw-border-opacity));
}

.\!border-border,
.\!hover\:border-border:hover,
.\!focus\:border-border:focus,
.\!checked\:border-border:checked {
    --tw-border-opacity: 1;
    border-color: rgba(236, 236, 236, var(--tw-border-opacity)) !important;
}

.ring-border,
.hover\:ring-border:hover,
.focus\:ring-border:focus,
.checked\:ring-border:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 236, 236, var(--tw-ring-opacity));
}

.\!ring-border,
.\!hover\:ring-border:hover,
.\!focus\:ring-border:focus,
.\!checked\:ring-border:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 236, 236, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-border,
    .sm\:hover\:text-border:hover,
    .sm\:focus\:text-border:focus,
    .sm\:checked\:text-border:checked {
        --tw-text-opacity: 1;
        color: rgba(236, 236, 236, var(--tw-text-opacity));
    }

    .\!sm\:text-border,
    .\!sm\:hover\:text-border:hover,
    .\!sm\:focus\:text-border:focus,
    .\!sm\:checked\:text-border:checked {
        --tw-text-opacity: 1;
        color: rgba(236, 236, 236, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-border,
    .sm\:hover\:bg-border:hover,
    .sm\:focus\:bg-border:focus,
    .sm\:checked\:bg-border:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(236, 236, 236, var(--tw-bg-opacity));
    }

    .\!sm\:bg-border,
    .\!sm\:hover\:bg-border:hover,
    .\!sm\:focus\:bg-border:focus,
    .\!sm\:checked\:bg-border:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(236, 236, 236, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-border,
    .sm\:hover\:border-border:hover,
    .sm\:focus\:border-border:focus,
    .sm\:checked\:border-border:checked {
        --tw-border-opacity: 1;
        border-color: rgba(236, 236, 236, var(--tw-border-opacity));
    }

    .\!sm\:border-border,
    .\!sm\:hover\:border-border:hover,
    .\!sm\:focus\:border-border:focus,
    .\!sm\:checked\:border-border:checked {
        --tw-border-opacity: 1;
        border-color: rgba(236, 236, 236, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-border,
    .sm\:hover\:ring-border:hover,
    .sm\:focus\:ring-border:focus,
    .sm\:checked\:ring-border:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(236, 236, 236, var(--tw-ring-opacity));
    }

    .\!sm\:ring-border,
    .\!sm\:hover\:ring-border:hover,
    .\!sm\:focus\:ring-border:focus,
    .\!sm\:checked\:ring-border:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(236, 236, 236, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-border,
    .md\:hover\:text-border:hover,
    .md\:focus\:text-border:focus,
    .md\:checked\:text-border:checked {
        --tw-text-opacity: 1;
        color: rgba(236, 236, 236, var(--tw-text-opacity));
    }

    .\!md\:text-border,
    .\!md\:hover\:text-border:hover,
    .\!md\:focus\:text-border:focus,
    .\!md\:checked\:text-border:checked {
        --tw-text-opacity: 1;
        color: rgba(236, 236, 236, var(--tw-text-opacity)) !important;
    }

    .md\:bg-border,
    .md\:hover\:bg-border:hover,
    .md\:focus\:bg-border:focus,
    .md\:checked\:bg-border:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(236, 236, 236, var(--tw-bg-opacity));
    }

    .\!md\:bg-border,
    .\!md\:hover\:bg-border:hover,
    .\!md\:focus\:bg-border:focus,
    .\!md\:checked\:bg-border:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(236, 236, 236, var(--tw-bg-opacity)) !important;
    }

    .md\:border-border,
    .md\:hover\:border-border:hover,
    .md\:focus\:border-border:focus,
    .md\:checked\:border-border:checked {
        --tw-border-opacity: 1;
        border-color: rgba(236, 236, 236, var(--tw-border-opacity));
    }

    .\!md\:border-border,
    .\!md\:hover\:border-border:hover,
    .\!md\:focus\:border-border:focus,
    .\!md\:checked\:border-border:checked {
        --tw-border-opacity: 1;
        border-color: rgba(236, 236, 236, var(--tw-border-opacity)) !important;
    }

    .md\:ring-border,
    .md\:hover\:ring-border:hover,
    .md\:focus\:ring-border:focus,
    .md\:checked\:ring-border:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(236, 236, 236, var(--tw-ring-opacity));
    }

    .\!md\:ring-border,
    .\!md\:hover\:ring-border:hover,
    .\!md\:focus\:ring-border:focus,
    .\!md\:checked\:ring-border:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(236, 236, 236, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-border,
    .lg\:hover\:text-border:hover,
    .lg\:focus\:text-border:focus,
    .lg\:checked\:text-border:checked {
        --tw-text-opacity: 1;
        color: rgba(236, 236, 236, var(--tw-text-opacity));
    }

    .\!lg\:text-border,
    .\!lg\:hover\:text-border:hover,
    .\!lg\:focus\:text-border:focus,
    .\!lg\:checked\:text-border:checked {
        --tw-text-opacity: 1;
        color: rgba(236, 236, 236, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-border,
    .lg\:hover\:bg-border:hover,
    .lg\:focus\:bg-border:focus,
    .lg\:checked\:bg-border:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(236, 236, 236, var(--tw-bg-opacity));
    }

    .\!lg\:bg-border,
    .\!lg\:hover\:bg-border:hover,
    .\!lg\:focus\:bg-border:focus,
    .\!lg\:checked\:bg-border:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(236, 236, 236, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-border,
    .lg\:hover\:border-border:hover,
    .lg\:focus\:border-border:focus,
    .lg\:checked\:border-border:checked {
        --tw-border-opacity: 1;
        border-color: rgba(236, 236, 236, var(--tw-border-opacity));
    }

    .\!lg\:border-border,
    .\!lg\:hover\:border-border:hover,
    .\!lg\:focus\:border-border:focus,
    .\!lg\:checked\:border-border:checked {
        --tw-border-opacity: 1;
        border-color: rgba(236, 236, 236, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-border,
    .lg\:hover\:ring-border:hover,
    .lg\:focus\:ring-border:focus,
    .lg\:checked\:ring-border:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(236, 236, 236, var(--tw-ring-opacity));
    }

    .\!lg\:ring-border,
    .\!lg\:hover\:ring-border:hover,
    .\!lg\:focus\:ring-border:focus,
    .\!lg\:checked\:ring-border:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(236, 236, 236, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-border,
    .xl\:hover\:text-border:hover,
    .xl\:focus\:text-border:focus,
    .xl\:checked\:text-border:checked {
        --tw-text-opacity: 1;
        color: rgba(236, 236, 236, var(--tw-text-opacity));
    }

    .\!xl\:text-border,
    .\!xl\:hover\:text-border:hover,
    .\!xl\:focus\:text-border:focus,
    .\!xl\:checked\:text-border:checked {
        --tw-text-opacity: 1;
        color: rgba(236, 236, 236, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-border,
    .xl\:hover\:bg-border:hover,
    .xl\:focus\:bg-border:focus,
    .xl\:checked\:bg-border:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(236, 236, 236, var(--tw-bg-opacity));
    }

    .\!xl\:bg-border,
    .\!xl\:hover\:bg-border:hover,
    .\!xl\:focus\:bg-border:focus,
    .\!xl\:checked\:bg-border:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(236, 236, 236, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-border,
    .xl\:hover\:border-border:hover,
    .xl\:focus\:border-border:focus,
    .xl\:checked\:border-border:checked {
        --tw-border-opacity: 1;
        border-color: rgba(236, 236, 236, var(--tw-border-opacity));
    }

    .\!xl\:border-border,
    .\!xl\:hover\:border-border:hover,
    .\!xl\:focus\:border-border:focus,
    .\!xl\:checked\:border-border:checked {
        --tw-border-opacity: 1;
        border-color: rgba(236, 236, 236, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-border,
    .xl\:hover\:ring-border:hover,
    .xl\:focus\:ring-border:focus,
    .xl\:checked\:ring-border:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(236, 236, 236, var(--tw-ring-opacity));
    }

    .\!xl\:ring-border,
    .\!xl\:hover\:ring-border:hover,
    .\!xl\:focus\:ring-border:focus,
    .\!xl\:checked\:ring-border:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(236, 236, 236, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-border,
    .\2xl\:hover\:text-border:hover,
    .\2xl\:focus\:text-border:focus,
    .\2xl\:checked\:text-border:checked {
        --tw-text-opacity: 1;
        color: rgba(236, 236, 236, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-border,
    .\!2xlsm\:hover\:text-border:hover,
    .\!2xlsm\:focus\:text-border:focus,
    .\!2xlsm\:checked\:text-border:checked {
        --tw-text-opacity: 1;
        color: rgba(236, 236, 236, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-border,
    .\2xl\:hover\:bg-border:hover,
    .\2xl\:focus\:bg-border:focus,
    .\2xl\:checked\:bg-border:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(236, 236, 236, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-border,
    .\!2xlsm\:hover\:bg-border:hover,
    .\!2xlsm\:focus\:bg-border:focus,
    .\!2xlsm\:checked\:bg-border:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(236, 236, 236, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-border,
    .\2xl\:hover\:border-border:hover,
    .\2xl\:focus\:border-border:focus,
    .\2xl\:checked\:border-border:checked {
        --tw-border-opacity: 1;
        border-color: rgba(236, 236, 236, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-border,
    .\!2xlsm\:hover\:border-border:hover,
    .\!2xlsm\:focus\:border-border:focus,
    .\!2xlsm\:checked\:border-border:checked {
        --tw-border-opacity: 1;
        border-color: rgba(236, 236, 236, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-border,
    .\2xl\:hover\:ring-border:hover,
    .\2xl\:focus\:ring-border:focus,
    .\2xl\:checked\:ring-border:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(236, 236, 236, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-border,
    .\!2xl\:hover\:ring-border:hover,
    .\!2xl\:focus\:ring-border:focus,
    .\!2xl\:checked\:ring-border:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(236, 236, 236, var(--tw-ring-opacity)) !important;
    }
}

//bg-hover
.text-bg-hover,
.hover\:text-bg-hover:hover,
.focus\:text-bg-hover:focus,
.checked\:text-bg-hover:checked {
    --tw-text-opacity: 1;
    color: rgba(250, 251, 252, var(--tw-text-opacity));
}

.\!text-bg-hover,
.\!hover\:text-bg-hover:hover,
.\!focus\:text-bg-hover:focus,
.\!checked\:text-bg-hover:checked {
    --tw-text-opacity: 1;
    color: rgba(250, 251, 252, var(--tw-text-opacity)) !important;
}

.bg-bg-hover,
.hover\:bg-bg-hover:hover,
.focus\:bg-bg-hover:focus,
.checked\:bg-bg-hover:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 251, 252, var(--tw-bg-opacity));
}

.\!bg-bg-hover,
.\!hover\:bg-bg-hover:hover,
.\!focus\:bg-bg-hover:focus,
.\!checked\:bg-bg-hover:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 251, 252, var(--tw-bg-opacity)) !important;
}

.border-bg-hover,
.hover\:border-bg-hover:hover,
.focus\:border-bg-hover:focus,
.checked\:border-bg-hover:checked {
    --tw-border-opacity: 1;
    border-color: rgba(250, 251, 252, var(--tw-border-opacity));
}

.\!border-bg-hover,
.\!hover\:border-bg-hover:hover,
.\!focus\:border-bg-hover:focus,
.\!checked\:border-bg-hover:checked {
    --tw-border-opacity: 1;
    border-color: rgba(250, 251, 252, var(--tw-border-opacity)) !important;
}

.ring-bg-hover,
.hover\:ring-bg-hover:hover,
.focus\:ring-bg-hover:focus,
.checked\:ring-bg-hover:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 251, 252, var(--tw-ring-opacity));
}

.\!ring-bg-hover,
.\!hover\:ring-bg-hover:hover,
.\!focus\:ring-bg-hover:focus,
.\!checked\:ring-bg-hover:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 251, 252, var(--tw-ring-opacity)) !important;
}

.background-hover,
.hover\:background-hover:hover,
.focus\:background-hover:focus,
.checked\:background-hover:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 248, 249, 1);
}

@media (min-width: 640px) {

    .sm\:text-bg-hover,
    .sm\:hover\:text-bg-hover:hover,
    .sm\:focus\:text-bg-hover:focus,
    .sm\:checked\:text-bg-hover:checked {
        --tw-text-opacity: 1;
        color: rgba(250, 251, 252, var(--tw-text-opacity));
    }

    .\!sm\:text-bg-hover,
    .\!sm\:hover\:text-bg-hover:hover,
    .\!sm\:focus\:text-bg-hover:focus,
    .\!sm\:checked\:text-bg-hover:checked {
        --tw-text-opacity: 1;
        color: rgba(250, 251, 252, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-bg-hover,
    .sm\:hover\:bg-bg-hover:hover,
    .sm\:focus\:bg-bg-hover:focus,
    .sm\:checked\:bg-bg-hover:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(250, 251, 252, var(--tw-bg-opacity));
    }

    .\!sm\:bg-bg-hover,
    .\!sm\:hover\:bg-bg-hover:hover,
    .\!sm\:focus\:bg-bg-hover:focus,
    .\!sm\:checked\:bg-bg-hover:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(250, 251, 252, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-bg-hover,
    .sm\:hover\:border-bg-hover:hover,
    .sm\:focus\:border-bg-hover:focus,
    .sm\:checked\:border-bg-hover:checked {
        --tw-border-opacity: 1;
        border-color: rgba(250, 251, 252, var(--tw-border-opacity));
    }

    .\!sm\:border-bg-hover,
    .\!sm\:hover\:border-bg-hover:hover,
    .\!sm\:focus\:border-bg-hover:focus,
    .\!sm\:checked\:border-bg-hover:checked {
        --tw-border-opacity: 1;
        border-color: rgba(250, 251, 252, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-bg-hover,
    .sm\:hover\:ring-bg-hover:hover,
    .sm\:focus\:ring-bg-hover:focus,
    .sm\:checked\:ring-bg-hover:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(250, 251, 252, var(--tw-ring-opacity));
    }

    .\!sm\:ring-bg-hover,
    .\!sm\:hover\:ring-bg-hover:hover,
    .\!sm\:focus\:ring-bg-hover:focus,
    .\!sm\:checked\:ring-bg-hover:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(250, 251, 252, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-bg-hover,
    .md\:hover\:text-bg-hover:hover,
    .md\:focus\:text-bg-hover:focus,
    .md\:checked\:text-bg-hover:checked {
        --tw-text-opacity: 1;
        color: rgba(250, 251, 252, var(--tw-text-opacity));
    }

    .\!md\:text-bg-hover,
    .\!md\:hover\:text-bg-hover:hover,
    .\!md\:focus\:text-bg-hover:focus,
    .\!md\:checked\:text-bg-hover:checked {
        --tw-text-opacity: 1;
        color: rgba(250, 251, 252, var(--tw-text-opacity)) !important;
    }

    .md\:bg-bg-hover,
    .md\:hover\:bg-bg-hover:hover,
    .md\:focus\:bg-bg-hover:focus,
    .md\:checked\:bg-bg-hover:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(250, 251, 252, var(--tw-bg-opacity));
    }

    .\!md\:bg-bg-hover,
    .\!md\:hover\:bg-bg-hover:hover,
    .\!md\:focus\:bg-bg-hover:focus,
    .\!md\:checked\:bg-bg-hover:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(250, 251, 252, var(--tw-bg-opacity)) !important;
    }

    .md\:border-bg-hover,
    .md\:hover\:border-bg-hover:hover,
    .md\:focus\:border-bg-hover:focus,
    .md\:checked\:border-bg-hover:checked {
        --tw-border-opacity: 1;
        border-color: rgba(250, 251, 252, var(--tw-border-opacity));
    }

    .\!md\:border-bg-hover,
    .\!md\:hover\:border-bg-hover:hover,
    .\!md\:focus\:border-bg-hover:focus,
    .\!md\:checked\:border-bg-hover:checked {
        --tw-border-opacity: 1;
        border-color: rgba(250, 251, 252, var(--tw-border-opacity)) !important;
    }

    .md\:ring-bg-hover,
    .md\:hover\:ring-bg-hover:hover,
    .md\:focus\:ring-bg-hover:focus,
    .md\:checked\:ring-bg-hover:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(250, 251, 252, var(--tw-ring-opacity));
    }

    .\!md\:ring-bg-hover,
    .\!md\:hover\:ring-bg-hover:hover,
    .\!md\:focus\:ring-bg-hover:focus,
    .\!md\:checked\:ring-bg-hover:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(250, 251, 252, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-bg-hover,
    .lg\:hover\:text-bg-hover:hover,
    .lg\:focus\:text-bg-hover:focus,
    .lg\:checked\:text-bg-hover:checked {
        --tw-text-opacity: 1;
        color: rgba(250, 251, 252, var(--tw-text-opacity));
    }

    .\!lg\:text-bg-hover,
    .\!lg\:hover\:text-bg-hover:hover,
    .\!lg\:focus\:text-bg-hover:focus,
    .\!lg\:checked\:text-bg-hover:checked {
        --tw-text-opacity: 1;
        color: rgba(250, 251, 252, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-bg-hover,
    .lg\:hover\:bg-bg-hover:hover,
    .lg\:focus\:bg-bg-hover:focus,
    .lg\:checked\:bg-bg-hover:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(250, 251, 252, var(--tw-bg-opacity));
    }

    .\!lg\:bg-bg-hover,
    .\!lg\:hover\:bg-bg-hover:hover,
    .\!lg\:focus\:bg-bg-hover:focus,
    .\!lg\:checked\:bg-bg-hover:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(250, 251, 252, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-bg-hover,
    .lg\:hover\:border-bg-hover:hover,
    .lg\:focus\:border-bg-hover:focus,
    .lg\:checked\:border-bg-hover:checked {
        --tw-border-opacity: 1;
        border-color: rgba(250, 251, 252, var(--tw-border-opacity));
    }

    .\!lg\:border-bg-hover,
    .\!lg\:hover\:border-bg-hover:hover,
    .\!lg\:focus\:border-bg-hover:focus,
    .\!lg\:checked\:border-bg-hover:checked {
        --tw-border-opacity: 1;
        border-color: rgba(250, 251, 252, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-bg-hover,
    .lg\:hover\:ring-bg-hover:hover,
    .lg\:focus\:ring-bg-hover:focus,
    .lg\:checked\:ring-bg-hover:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(250, 251, 252, var(--tw-ring-opacity));
    }

    .\!lg\:ring-bg-hover,
    .\!lg\:hover\:ring-bg-hover:hover,
    .\!lg\:focus\:ring-bg-hover:focus,
    .\!lg\:checked\:ring-bg-hover:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(250, 251, 252, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-bg-hover,
    .xl\:hover\:text-bg-hover:hover,
    .xl\:focus\:text-bg-hover:focus,
    .xl\:checked\:text-bg-hover:checked {
        --tw-text-opacity: 1;
        color: rgba(250, 251, 252, var(--tw-text-opacity));
    }

    .\!xl\:text-bg-hover,
    .\!xl\:hover\:text-bg-hover:hover,
    .\!xl\:focus\:text-bg-hover:focus,
    .\!xl\:checked\:text-bg-hover:checked {
        --tw-text-opacity: 1;
        color: rgba(250, 251, 252, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-bg-hover,
    .xl\:hover\:bg-bg-hover:hover,
    .xl\:focus\:bg-bg-hover:focus,
    .xl\:checked\:bg-bg-hover:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(250, 251, 252, var(--tw-bg-opacity));
    }

    .\!xl\:bg-bg-hover,
    .\!xl\:hover\:bg-bg-hover:hover,
    .\!xl\:focus\:bg-bg-hover:focus,
    .\!xl\:checked\:bg-bg-hover:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(250, 251, 252, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-bg-hover,
    .xl\:hover\:border-bg-hover:hover,
    .xl\:focus\:border-bg-hover:focus,
    .xl\:checked\:border-bg-hover:checked {
        --tw-border-opacity: 1;
        border-color: rgba(250, 251, 252, var(--tw-border-opacity));
    }

    .\!xl\:border-bg-hover,
    .\!xl\:hover\:border-bg-hover:hover,
    .\!xl\:focus\:border-bg-hover:focus,
    .\!xl\:checked\:border-bg-hover:checked {
        --tw-border-opacity: 1;
        border-color: rgba(250, 251, 252, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-bg-hover,
    .xl\:hover\:ring-bg-hover:hover,
    .xl\:focus\:ring-bg-hover:focus,
    .xl\:checked\:ring-bg-hover:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(250, 251, 252, var(--tw-ring-opacity));
    }

    .\!xl\:ring-bg-hover,
    .\!xl\:hover\:ring-bg-hover:hover,
    .\!xl\:focus\:ring-bg-hover:focus,
    .\!xl\:checked\:ring-bg-hover:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(250, 251, 252, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-bg-hover,
    .\2xl\:hover\:text-bg-hover:hover,
    .\2xl\:focus\:text-bg-hover:focus,
    .\2xl\:checked\:text-bg-hover:checked {
        --tw-text-opacity: 1;
        color: rgba(250, 251, 252, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-bg-hover,
    .\!2xlsm\:hover\:text-bg-hover:hover,
    .\!2xlsm\:focus\:text-bg-hover:focus,
    .\!2xlsm\:checked\:text-bg-hover:checked {
        --tw-text-opacity: 1;
        color: rgba(250, 251, 252, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-bg-hover,
    .\2xl\:hover\:bg-bg-hover:hover,
    .\2xl\:focus\:bg-bg-hover:focus,
    .\2xl\:checked\:bg-bg-hover:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(250, 251, 252, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-bg-hover,
    .\!2xlsm\:hover\:bg-bg-hover:hover,
    .\!2xlsm\:focus\:bg-bg-hover:focus,
    .\!2xlsm\:checked\:bg-bg-hover:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(250, 251, 252, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-bg-hover,
    .\2xl\:hover\:border-bg-hover:hover,
    .\2xl\:focus\:border-bg-hover:focus,
    .\2xl\:checked\:border-bg-hover:checked {
        --tw-border-opacity: 1;
        border-color: rgba(250, 251, 252, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-bg-hover,
    .\!2xlsm\:hover\:border-bg-hover:hover,
    .\!2xlsm\:focus\:border-bg-hover:focus,
    .\!2xlsm\:checked\:border-bg-hover:checked {
        --tw-border-opacity: 1;
        border-color: rgba(250, 251, 252, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-bg-hover,
    .\2xl\:hover\:ring-bg-hover:hover,
    .\2xl\:focus\:ring-bg-hover:focus,
    .\2xl\:checked\:ring-bg-hover:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(250, 251, 252, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-bg-hover,
    .\!2xl\:hover\:ring-bg-hover:hover,
    .\!2xl\:focus\:ring-bg-hover:focus,
    .\!2xl\:checked\:ring-bg-hover:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(250, 251, 252, var(--tw-ring-opacity)) !important;
    }
}

//light-blue
.text-light-blue,
.hover\:text-light-blue:hover,
.focus\:text-light-blue:focus,
.checked\:text-light-blue:checked {
    --tw-text-opacity: 1;
    color: rgba(227, 249, 253, var(--tw-text-opacity));
}

.\!text-light-blue,
.\!hover\:text-light-blue:hover,
.\!focus\:text-light-blue:focus,
.\!checked\:text-light-blue:checked {
    --tw-text-opacity: 1;
    color: rgba(227, 249, 253, var(--tw-text-opacity)) !important;
}

.bg-light-blue,
.hover\:bg-light-blue:hover,
.focus\:bg-light-blue:focus,
.checked\:bg-light-blue:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 249, 253, var(--tw-bg-opacity));
}

.\!bg-light-blue,
.\!hover\:bg-light-blue:hover,
.\!focus\:bg-light-blue:focus,
.\!checked\:bg-light-blue:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 249, 253, var(--tw-bg-opacity)) !important;
}

.border-light-blue,
.hover\:border-light-blue:hover,
.focus\:border-light-blue:focus,
.checked\:border-light-blue:checked {
    --tw-border-opacity: 1;
    border-color: rgba(227, 249, 253, var(--tw-border-opacity));
}

.\!border-light-blue,
.\!hover\:border-light-blue:hover,
.\!focus\:border-light-blue:focus,
.\!checked\:border-light-blue:checked {
    --tw-border-opacity: 1;
    border-color: rgba(227, 249, 253, var(--tw-border-opacity)) !important;
}

.ring-light-blue,
.hover\:ring-light-blue:hover,
.focus\:ring-light-blue:focus,
.checked\:ring-light-blue:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 249, 253, var(--tw-ring-opacity));
}

.\!ring-light-blue,
.\!hover\:ring-light-blue:hover,
.\!focus\:ring-light-blue:focus,
.\!checked\:ring-light-blue:checked {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(227, 249, 253, var(--tw-ring-opacity)) !important;
}

@media (min-width: 640px) {

    .sm\:text-light-blue,
    .sm\:hover\:text-light-blue:hover,
    .sm\:focus\:text-light-blue:focus,
    .sm\:checked\:text-light-blue:checked {
        --tw-text-opacity: 1;
        color: rgba(227, 249, 253, var(--tw-text-opacity));
    }

    .\!sm\:text-light-blue,
    .\!sm\:hover\:text-light-blue:hover,
    .\!sm\:focus\:text-light-blue:focus,
    .\!sm\:checked\:text-light-blue:checked {
        --tw-text-opacity: 1;
        color: rgba(227, 249, 253, var(--tw-text-opacity)) !important;
    }

    .sm\:bg-light-blue,
    .sm\:hover\:bg-light-blue:hover,
    .sm\:focus\:bg-light-blue:focus,
    .sm\:checked\:bg-light-blue:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(227, 249, 253, var(--tw-bg-opacity));
    }

    .\!sm\:bg-light-blue,
    .\!sm\:hover\:bg-light-blue:hover,
    .\!sm\:focus\:bg-light-blue:focus,
    .\!sm\:checked\:bg-light-blue:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(227, 249, 253, var(--tw-bg-opacity)) !important;
    }

    .sm\:border-light-blue,
    .sm\:hover\:border-light-blue:hover,
    .sm\:focus\:border-light-blue:focus,
    .sm\:checked\:border-light-blue:checked {
        --tw-border-opacity: 1;
        border-color: rgba(227, 249, 253, var(--tw-border-opacity));
    }

    .\!sm\:border-light-blue,
    .\!sm\:hover\:border-light-blue:hover,
    .\!sm\:focus\:border-light-blue:focus,
    .\!sm\:checked\:border-light-blue:checked {
        --tw-border-opacity: 1;
        border-color: rgba(227, 249, 253, var(--tw-border-opacity)) !important;
    }

    .sm\:ring-light-blue,
    .sm\:hover\:ring-light-blue:hover,
    .sm\:focus\:ring-light-blue:focus,
    .sm\:checked\:ring-light-blue:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(227, 249, 253, var(--tw-ring-opacity));
    }

    .\!sm\:ring-light-blue,
    .\!sm\:hover\:ring-light-blue:hover,
    .\!sm\:focus\:ring-light-blue:focus,
    .\!sm\:checked\:ring-light-blue:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(227, 249, 253, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 768px) {

    .md\:text-light-blue,
    .md\:hover\:text-light-blue:hover,
    .md\:focus\:text-light-blue:focus,
    .md\:checked\:text-light-blue:checked {
        --tw-text-opacity: 1;
        color: rgba(227, 249, 253, var(--tw-text-opacity));
    }

    .\!md\:text-light-blue,
    .\!md\:hover\:text-light-blue:hover,
    .\!md\:focus\:text-light-blue:focus,
    .\!md\:checked\:text-light-blue:checked {
        --tw-text-opacity: 1;
        color: rgba(227, 249, 253, var(--tw-text-opacity)) !important;
    }

    .md\:bg-light-blue,
    .md\:hover\:bg-light-blue:hover,
    .md\:focus\:bg-light-blue:focus,
    .md\:checked\:bg-light-blue:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(227, 249, 253, var(--tw-bg-opacity));
    }

    .\!md\:bg-light-blue,
    .\!md\:hover\:bg-light-blue:hover,
    .\!md\:focus\:bg-light-blue:focus,
    .\!md\:checked\:bg-light-blue:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(227, 249, 253, var(--tw-bg-opacity)) !important;
    }

    .md\:border-light-blue,
    .md\:hover\:border-light-blue:hover,
    .md\:focus\:border-light-blue:focus,
    .md\:checked\:border-light-blue:checked {
        --tw-border-opacity: 1;
        border-color: rgba(227, 249, 253, var(--tw-border-opacity));
    }

    .\!md\:border-light-blue,
    .\!md\:hover\:border-light-blue:hover,
    .\!md\:focus\:border-light-blue:focus,
    .\!md\:checked\:border-light-blue:checked {
        --tw-border-opacity: 1;
        border-color: rgba(227, 249, 253, var(--tw-border-opacity)) !important;
    }

    .md\:ring-light-blue,
    .md\:hover\:ring-light-blue:hover,
    .md\:focus\:ring-light-blue:focus,
    .md\:checked\:ring-light-blue:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(227, 249, 253, var(--tw-ring-opacity));
    }

    .\!md\:ring-light-blue,
    .\!md\:hover\:ring-light-blue:hover,
    .\!md\:focus\:ring-light-blue:focus,
    .\!md\:checked\:ring-light-blue:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(227, 249, 253, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1024px) {

    .lg\:text-light-blue,
    .lg\:hover\:text-light-blue:hover,
    .lg\:focus\:text-light-blue:focus,
    .lg\:checked\:text-light-blue:checked {
        --tw-text-opacity: 1;
        color: rgba(227, 249, 253, var(--tw-text-opacity));
    }

    .\!lg\:text-light-blue,
    .\!lg\:hover\:text-light-blue:hover,
    .\!lg\:focus\:text-light-blue:focus,
    .\!lg\:checked\:text-light-blue:checked {
        --tw-text-opacity: 1;
        color: rgba(227, 249, 253, var(--tw-text-opacity)) !important;
    }

    .lg\:bg-light-blue,
    .lg\:hover\:bg-light-blue:hover,
    .lg\:focus\:bg-light-blue:focus,
    .lg\:checked\:bg-light-blue:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(227, 249, 253, var(--tw-bg-opacity));
    }

    .\!lg\:bg-light-blue,
    .\!lg\:hover\:bg-light-blue:hover,
    .\!lg\:focus\:bg-light-blue:focus,
    .\!lg\:checked\:bg-light-blue:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(227, 249, 253, var(--tw-bg-opacity)) !important;
    }

    .lg\:border-light-blue,
    .lg\:hover\:border-light-blue:hover,
    .lg\:focus\:border-light-blue:focus,
    .lg\:checked\:border-light-blue:checked {
        --tw-border-opacity: 1;
        border-color: rgba(227, 249, 253, var(--tw-border-opacity));
    }

    .\!lg\:border-light-blue,
    .\!lg\:hover\:border-light-blue:hover,
    .\!lg\:focus\:border-light-blue:focus,
    .\!lg\:checked\:border-light-blue:checked {
        --tw-border-opacity: 1;
        border-color: rgba(227, 249, 253, var(--tw-border-opacity)) !important;
    }

    .lg\:ring-light-blue,
    .lg\:hover\:ring-light-blue:hover,
    .lg\:focus\:ring-light-blue:focus,
    .lg\:checked\:ring-light-blue:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(227, 249, 253, var(--tw-ring-opacity));
    }

    .\!lg\:ring-light-blue,
    .\!lg\:hover\:ring-light-blue:hover,
    .\!lg\:focus\:ring-light-blue:focus,
    .\!lg\:checked\:ring-light-blue:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(227, 249, 253, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1280px) {

    .xl\:text-light-blue,
    .xl\:hover\:text-light-blue:hover,
    .xl\:focus\:text-light-blue:focus,
    .xl\:checked\:text-light-blue:checked {
        --tw-text-opacity: 1;
        color: rgba(227, 249, 253, var(--tw-text-opacity));
    }

    .\!xl\:text-light-blue,
    .\!xl\:hover\:text-light-blue:hover,
    .\!xl\:focus\:text-light-blue:focus,
    .\!xl\:checked\:text-light-blue:checked {
        --tw-text-opacity: 1;
        color: rgba(227, 249, 253, var(--tw-text-opacity)) !important;
    }

    .xl\:bg-light-blue,
    .xl\:hover\:bg-light-blue:hover,
    .xl\:focus\:bg-light-blue:focus,
    .xl\:checked\:bg-light-blue:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(227, 249, 253, var(--tw-bg-opacity));
    }

    .\!xl\:bg-light-blue,
    .\!xl\:hover\:bg-light-blue:hover,
    .\!xl\:focus\:bg-light-blue:focus,
    .\!xl\:checked\:bg-light-blue:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(227, 249, 253, var(--tw-bg-opacity)) !important;
    }

    .xl\:border-light-blue,
    .xl\:hover\:border-light-blue:hover,
    .xl\:focus\:border-light-blue:focus,
    .xl\:checked\:border-light-blue:checked {
        --tw-border-opacity: 1;
        border-color: rgba(227, 249, 253, var(--tw-border-opacity));
    }

    .\!xl\:border-light-blue,
    .\!xl\:hover\:border-light-blue:hover,
    .\!xl\:focus\:border-light-blue:focus,
    .\!xl\:checked\:border-light-blue:checked {
        --tw-border-opacity: 1;
        border-color: rgba(227, 249, 253, var(--tw-border-opacity)) !important;
    }

    .xl\:ring-light-blue,
    .xl\:hover\:ring-light-blue:hover,
    .xl\:focus\:ring-light-blue:focus,
    .xl\:checked\:ring-light-blue:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(227, 249, 253, var(--tw-ring-opacity));
    }

    .\!xl\:ring-light-blue,
    .\!xl\:hover\:ring-light-blue:hover,
    .\!xl\:focus\:ring-light-blue:focus,
    .\!xl\:checked\:ring-light-blue:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(227, 249, 253, var(--tw-ring-opacity)) !important;
    }
}

@media (min-width: 1536px) {

    .\2xl\:text-light-blue,
    .\2xl\:hover\:text-light-blue:hover,
    .\2xl\:focus\:text-light-blue:focus,
    .\2xl\:checked\:text-light-blue:checked {
        --tw-text-opacity: 1;
        color: rgba(227, 249, 253, var(--tw-text-opacity));
    }

    .\!2xlsm\:text-light-blue,
    .\!2xlsm\:hover\:text-light-blue:hover,
    .\!2xlsm\:focus\:text-light-blue:focus,
    .\!2xlsm\:checked\:text-light-blue:checked {
        --tw-text-opacity: 1;
        color: rgba(227, 249, 253, var(--tw-text-opacity)) !important;
    }

    .\2xl\:bg-light-blue,
    .\2xl\:hover\:bg-light-blue:hover,
    .\2xl\:focus\:bg-light-blue:focus,
    .\2xl\:checked\:bg-light-blue:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(227, 249, 253, var(--tw-bg-opacity));
    }

    .\!2xlsm\:bg-light-blue,
    .\!2xlsm\:hover\:bg-light-blue:hover,
    .\!2xlsm\:focus\:bg-light-blue:focus,
    .\!2xlsm\:checked\:bg-light-blue:checked {
        --tw-bg-opacity: 1;
        background-color: rgba(227, 249, 253, var(--tw-bg-opacity)) !important;
    }

    .\2xl\:border-light-blue,
    .\2xl\:hover\:border-light-blue:hover,
    .\2xl\:focus\:border-light-blue:focus,
    .\2xl\:checked\:border-light-blue:checked {
        --tw-border-opacity: 1;
        border-color: rgba(227, 249, 253, var(--tw-border-opacity));
    }

    .\!2xlsm\:border-light-blue,
    .\!2xlsm\:hover\:border-light-blue:hover,
    .\!2xlsm\:focus\:border-light-blue:focus,
    .\!2xlsm\:checked\:border-light-blue:checked {
        --tw-border-opacity: 1;
        border-color: rgba(227, 249, 253, var(--tw-border-opacity)) !important;
    }

    .\2xl\:ring-light-blue,
    .\2xl\:hover\:ring-light-blue:hover,
    .\2xl\:focus\:ring-light-blue:focus,
    .\2xl\:checked\:ring-light-blue:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(227, 249, 253, var(--tw-ring-opacity));
    }

    .\!2xl\:ring-light-blue,
    .\!2xl\:hover\:ring-light-blue:hover,
    .\!2xl\:focus\:ring-light-blue:focus,
    .\!2xl\:checked\:ring-light-blue:checked {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(227, 249, 253, var(--tw-ring-opacity)) !important;
    }
}


.border-opacity-5,
.hover\:border-opacity-5:hover,
.focus\:border-opacity-5:focus,
.disabled\:border-opacity-5:disabled {
    --tw-border-opacity: 0.05;
}

.\!border-opacity-5,
.\!hover\:border-opacity-5:hover,
.\!focus\:border-opacity-5:focus,
.\!disabled\:border-opacity-5:disabled {
    --tw-border-opacity: 0.05 !important;
}

.text-opacity-5,
.hover\:text-opacity-5:hover,
.focus\:text-opacity-5:focus,
.disabled\:text-opacity-5:disabled {
    --tw-text-opacity: 0.05;
}

.\!text-opacity-5,
.\!hover\:text-opacity-5:hover,
.\!focus\:text-opacity-5:focus,
.\!disabled\:text-opacity-5:disabled {
    --tw-text-opacity: 0.05 !important;
}

.bg-opacity-5,
.hover\:bg-opacity-5:hover,
.focus\:bg-opacity-5:focus,
.disabled\:bg-opacity-5:disabled {
    --tw-bg-opacity: 0.05;
}

.\!bg-opacity-5,
.\!hover\:bg-opacity-5:hover,
.\!focus\:bg-opacity-5:focus,
.\!disabled\:bg-opacity-5:disabled {
    --tw-bg-opacity: 0.05 !important;
}

.ring-opacity-5,
.hover\:ring-opacity-5:hover,
.focus\:ring-opacity-5:focus,
.disabled\:ring-opacity-5:disabled {
    --tw-ring-opacity: 0.05;
}

.\!ring-opacity-5,
.\!hover\:ring-opacity-5:hover,
.\!focus\:ring-opacity-5:focus,
.\!disabled\:ring-opacity-5:disabled {
    --tw-ring-opacity: 0.05 !important;
}


.border-opacity-10,
.hover\:border-opacity-10:hover,
.focus\:border-opacity-10:focus,
.disabled\:border-opacity-10:disabled {
    --tw-border-opacity: 0.1;
}

.\!border-opacity-10,
.\!hover\:border-opacity-10:hover,
.\!focus\:border-opacity-10:focus,
.\!disabled\:border-opacity-10:disabled {
    --tw-border-opacity: 0.1 !important;
}

.text-opacity-10,
.hover\:text-opacity-10:hover,
.focus\:text-opacity-10:focus,
.disabled\:text-opacity-10:disabled {
    --tw-text-opacity: 0.1;
}

.\!text-opacity-10,
.\!hover\:text-opacity-10:hover,
.\!focus\:text-opacity-10:focus,
.\!disabled\:text-opacity-10:disabled {
    --tw-text-opacity: 0.1 !important;
}

.bg-opacity-10,
.hover\:bg-opacity-10:hover,
.focus\:bg-opacity-10:focus,
.disabled\:bg-opacity-10:disabled {
    --tw-bg-opacity: 0.1;
}

.\!bg-opacity-10,
.\!hover\:bg-opacity-10:hover,
.\!focus\:bg-opacity-10:focus,
.\!disabled\:bg-opacity-10:disabled {
    --tw-bg-opacity: 0.1 !important;
}

.ring-opacity-10,
.hover\:ring-opacity-10:hover,
.focus\:ring-opacity-10:focus,
.disabled\:ring-opacity-10:disabled {
    --tw-ring-opacity: 0.1;
}

.\!ring-opacity-10,
.\!hover\:ring-opacity-10:hover,
.\!focus\:ring-opacity-10:focus,
.\!disabled\:ring-opacity-10:disabled {
    --tw-ring-opacity: 0.1 !important;
}


.border-opacity-20,
.hover\:border-opacity-20:hover,
.focus\:border-opacity-20:focus,
.disabled\:border-opacity-20:disabled {
    --tw-border-opacity: 0.2;
}

.\!border-opacity-20,
.\!hover\:border-opacity-20:hover,
.\!focus\:border-opacity-20:focus,
.\!disabled\:border-opacity-20:disabled {
    --tw-border-opacity: 0.2 !important;
}

.text-opacity-20,
.hover\:text-opacity-20:hover,
.focus\:text-opacity-20:focus,
.disabled\:text-opacity-20:disabled {
    --tw-text-opacity: 0.2;
}

.\!text-opacity-20,
.\!hover\:text-opacity-20:hover,
.\!focus\:text-opacity-20:focus,
.\!disabled\:text-opacity-20:disabled {
    --tw-text-opacity: 0.2 !important;
}

.bg-opacity-20,
.hover\:bg-opacity-20:hover,
.focus\:bg-opacity-20:focus,
.disabled\:bg-opacity-20:disabled {
    --tw-bg-opacity: 0.2;
}

.\!bg-opacity-20,
.\!hover\:bg-opacity-20:hover,
.\!focus\:bg-opacity-20:focus,
.\!disabled\:bg-opacity-20:disabled {
    --tw-bg-opacity: 0.2 !important;
}

.ring-opacity-20,
.hover\:ring-opacity-20:hover,
.focus\:ring-opacity-20:focus,
.disabled\:ring-opacity-20:disabled {
    --tw-ring-opacity: 0.2;
}

.\!ring-opacity-20,
.\!hover\:ring-opacity-20:hover,
.\!focus\:ring-opacity-20:focus,
.\!disabled\:ring-opacity-20:disabled {
    --tw-ring-opacity: 0.2 !important;
}


.border-opacity-50,
.hover\:border-opacity-50:hover,
.focus\:border-opacity-50:focus,
.disabled\:border-opacity-50:disabled {
    --tw-border-opacity: 0.5;
}

.\!border-opacity-50,
.\!hover\:border-opacity-50:hover,
.\!focus\:border-opacity-50:focus,
.\!disabled\:border-opacity-50:disabled {
    --tw-border-opacity: 0.5 !important;
}

.text-opacity-50,
.hover\:text-opacity-50:hover,
.focus\:text-opacity-50:focus,
.disabled\:text-opacity-50:disabled {
    --tw-text-opacity: 0.5;
}

.\!text-opacity-50,
.\!hover\:text-opacity-50:hover,
.\!focus\:text-opacity-50:focus,
.\!disabled\:text-opacity-50:disabled {
    --tw-text-opacity: 0.5 !important;
}

.bg-opacity-50,
.hover\:bg-opacity-50:hover,
.focus\:bg-opacity-50:focus,
.disabled\:bg-opacity-50:disabled {
    --tw-bg-opacity: 0.5;
}

.\!bg-opacity-50,
.\!hover\:bg-opacity-50:hover,
.\!focus\:bg-opacity-50:focus,
.\!disabled\:bg-opacity-50:disabled {
    --tw-bg-opacity: 0.5 !important;
}

.ring-opacity-50,
.hover\:ring-opacity-50:hover,
.focus\:ring-opacity-50:focus,
.disabled\:ring-opacity-50:disabled {
    --tw-ring-opacity: 0.5;
}

.\!ring-opacity-50,
.\!hover\:ring-opacity-50:hover,
.\!focus\:ring-opacity-50:focus,
.\!disabled\:ring-opacity-50:disabled {
    --tw-ring-opacity: 0.5 !important;
}


.border-opacity-80,
.hover\:border-opacity-80:hover,
.focus\:border-opacity-80:focus,
.disabled\:border-opacity-80:disabled {
    --tw-border-opacity: 0.8;
}

.\!border-opacity-80,
.\!hover\:border-opacity-80:hover,
.\!focus\:border-opacity-80:focus,
.\!disabled\:border-opacity-80:disabled {
    --tw-border-opacity: 0.8 !important;
}

.text-opacity-80,
.hover\:text-opacity-80:hover,
.focus\:text-opacity-80:focus,
.disabled\:text-opacity-80:disabled {
    --tw-text-opacity: 0.8;
}

.\!text-opacity-80,
.\!hover\:text-opacity-80:hover,
.\!focus\:text-opacity-80:focus,
.\!disabled\:text-opacity-80:disabled {
    --tw-text-opacity: 0.8 !important;
}

.bg-opacity-80,
.hover\:bg-opacity-80:hover,
.focus\:bg-opacity-80:focus,
.disabled\:bg-opacity-80:disabled {
    --tw-bg-opacity: 0.8;
}

.\!bg-opacity-80,
.\!hover\:bg-opacity-80:hover,
.\!focus\:bg-opacity-80:focus,
.\!disabled\:bg-opacity-80:disabled {
    --tw-bg-opacity: 0.8 !important;
}

.ring-opacity-80,
.hover\:ring-opacity-80:hover,
.focus\:ring-opacity-80:focus,
.disabled\:ring-opacity-80:disabled {
    --tw-ring-opacity: 0.8;
}

.\!ring-opacity-80,
.\!hover\:ring-opacity-80:hover,
.\!focus\:ring-opacity-80:focus,
.\!disabled\:ring-opacity-80:disabled {
    --tw-ring-opacity: 0.8 !important;
}


.border-opacity-100,
.hover\:border-opacity-100:hover,
.focus\:border-opacity-100:focus,
.disabled\:border-opacity-100:disabled {
    --tw-border-opacity: 1;
}

.\!border-opacity-100,
.\!hover\:border-opacity-100:hover,
.\!focus\:border-opacity-100:focus,
.\!disabled\:border-opacity-100:disabled {
    --tw-border-opacity: 1 !important;
}

.text-opacity-100,
.hover\:text-opacity-100:hover,
.focus\:text-opacity-100:focus,
.disabled\:text-opacity-100:disabled {
    --tw-text-opacity: 1;
}

.\!text-opacity-100,
.\!hover\:text-opacity-100:hover,
.\!focus\:text-opacity-100:focus,
.\!disabled\:text-opacity-100:disabled {
    --tw-text-opacity: 1 !important;
}

.bg-opacity-100,
.hover\:bg-opacity-100:hover,
.focus\:bg-opacity-100:focus,
.disabled\:bg-opacity-100:disabled {
    --tw-bg-opacity: 1;
}

.\!bg-opacity-100,
.\!hover\:bg-opacity-100:hover,
.\!focus\:bg-opacity-100:focus,
.\!disabled\:bg-opacity-100:disabled {
    --tw-bg-opacity: 1 !important;
}

.ring-opacity-100,
.hover\:ring-opacity-100:hover,
.focus\:ring-opacity-100:focus,
.disabled\:ring-opacity-100:disabled {
    --tw-ring-opacity: 1;
}

.\!ring-opacity-100,
.\!hover\:ring-opacity-100:hover,
.\!focus\:ring-opacity-100:focus,
.\!disabled\:ring-opacity-100:disabled {
    --tw-ring-opacity: 1 !important;
}