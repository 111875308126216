.calendar-container {
    .body .today {
        .weekDays-selector {
            border: 3px solid rgba(57, 109, 124, 1);
            background-color: white;
            color: rgba(57, 109, 124, 1);
        }

    }

    .body .selected {
        .weekDays-selector {
            background: rgba(57, 109, 124, 1);
            color: #ffffff;
            border: 3px solid rgba(57, 109, 124, 1);
        }
    }

    .weekDays-selector {
        border-radius: 6px;
        border: 3px solid rgba(237, 237, 237, 1);
        text-align: center;
        cursor: pointer;
    }
}