.modal-add-program {
    .program-type {
        width: 140px;
        height: 100px;
        border-radius: 4px;
        border-color: rgba(236, 236, 236, 1);
        background-color: rgba(249, 250, 251, 1);

        .program {
            display: block;
        }

        .program-white {
            display: none;
        }

        &:hover {
            background-color: rgba(57, 109, 124, 1);
            box-shadow: 0 0 5px rgba(0, 203, 229, 1);
            border-color: rgba(57, 109, 124, 1);

            .program {
                display: none;
            }

            .program-white {
                display: block;
            }
        }
    }
}