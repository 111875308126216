.modal-edit-exercise {
    .check-all-button {
        border-radius: 6px;
        height: 50px;
        width: 50px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 0.875rem;

        &.checked {
            background: rgba(57, 109, 124, 1);
            color: #ffffff;
            border: 3px solid rgba(57, 109, 124, 1);
        }

        &.unchecked {
            border: 3px solid rgba(237, 237, 237, 1)
        }
    }

    .file-upload {
        margin-top: 10px;
    }

    >div {
        width: 1060px;
        max-width: 1060px;
    }
}

@media (max-width: 1024px) {
    .modal-edit-exercise {
        >div {
            height: 100% !important;
        }

        .modal-body {
            overflow: unset !important;
        }
    }


}