.my-tokens {
    .expire-button {
        &.bg-blue-700 {
            background: #F05252;
        }

        &:focus {
            box-shadow: unset !important;
        }
    }

    .text-expired {
        color: #E3A008
    }

    .token-expire-date {
        min-width: 150px;
    }
}

@media screen and (max-width: 640px) {
    .my-tokens {
        .token-code {
            width: 80%;
            padding-right: 10px;
        }
    }
}