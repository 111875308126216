.update-patient {
    input {
        height: 34px !important;
    }
}

@media screen and (max-width: 1500px) {
    .update-patient {
        .form {
            width: 100%;
        }
    }
}

@media screen and (max-width: 640px) {
    .update-patient {
        .form {
            flex-direction: column;

            .input-name {
                margin-bottom: 10px;
            }
        }

        .name {
            justify-content: center;
        }
    }
}

@media screen and (max-width: 540px) {
    .update-patient {
        .form {
            flex-direction: column;

            .input-name {
                margin-bottom: 10px;
            }
        }
    }
}