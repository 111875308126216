.create-organization {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: white
  }
}