.programs {
    .css-13cymwt-control {
        border-radius: 6px !important;
    }

    .css-1u9des2-indicatorSeparator {
        display: none;
    }

    .css-t3ipsp-control {
        border-radius: 6px !important;
        box-shadow: unset !important;

        border-color: rgba(12, 192, 223, 1);

        &:hover {
            border-color: rgba(12, 192, 223, 1);
        }
    }
}