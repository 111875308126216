.detail-tab {
    .box-edit {
        margin-top: -8px;
    }
}

@media (max-width: 768px) {
    .detail-tab {
        .card {
            max-height: fit-content;
            margin-bottom: 0px;
        }

        .card-title {
            margin-top: 10px;

        }


        .card-content {

            p,
            .role {
                margin-top: 2px;
            }

            .edit-field {
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
    }
}