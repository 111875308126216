.weekDays-selector {
    display: flex;
    flex-wrap: wrap;
}
.weekDays-selector input {
    display: none !important;
}

.weekDays-selector input[type=checkbox]+label {
    border-radius: 6px;
    height: 50px;
    width: 50px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    border: 3px solid rgba(237, 237, 237, 1)
}

.weekDays-selector input[type=checkbox]:checked+label {
    background: rgba(57, 109, 124, 1);
    color: #ffffff;
    border: 3px solid rgba(57, 109, 124, 1);
}