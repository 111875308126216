.new-blank-program {
    .exercise {
        height: calc(100vh - 277px);
    }

    .header-program {
        //background: linear-gradient(180deg, #ACE3F2 12.58%, #F0E8D9 85.27%);
        background: rgba(57,109,124,0.1);
    }

    .input-name {
        input {
            height: 34px !important;
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: #00000099;
        border-radius: 100px;
    }

    ::-webkit-scrollbar-track {
        background: white;
    }
}

@media (max-width: 1300px) {
    .new-blank-program {
        .exercise-card {
            padding: 0 50px;
        }
    }
}


@media (max-width: 1200px) {
    .new-blank-program {
        .exercise-card {
            padding: 0 15px;
        }
        .input-name {
            flex-direction: column;

            .input-text {
                width: 100%;
            }

            .button {
                width: 100%;
                margin-top: 5px;
            }

        }

        .header-program {
            align-items: flex-start;
        }
    }

}

@media (max-width: 750px) {
    .new-blank-program {
        .program-infor {
            flex-wrap: wrap;

            .name {
                width: 100%;
            }

            .type,
            .status {
                width: 50%;
            }

            .type-template {
                width: 100%;
            }
        }

        .header-program {
            flex-direction: column;

            .active-button {
                margin-top: 10px;
                margin-left: 46px;
            }

            >div {
                width: 100%;
            }
        }
    }
}