//media
.role-modal-container {
    input {
        border-radius: 6px !important;
    }

    .react-select__control {
        border-radius: 6px !important;
    }
}

@media (max-height: 500px) {
    .role-modal-container {
        .role-modal {
            >div {
                height: 100% !important;
            }
        }

        ::-webkit-scrollbar {
            display: none;
        }
    }
}

@media screen and (min-width: 1025px) {
    .role-modal-container {
        .role-modal {
            .grid-cols-custom {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }

            .gap-custom {
                gap: 1rem;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .role-modal-container {
        .role-modal {
            >div {
                height: unset !important;
            }

            .grid-cols-custom {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            .gap-custom {
                gap: 0.5rem;
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .role-modal-container {
        .role-modal {
            .grid-cols-custom {
                grid-template-columns: repeat(1, minmax(0, 1fr));

                div[class='flex'] {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}