.exercise-item-container {

    .exercise-item {
        // border-radius: 10px 10px 0 10px;

        .photo-bg {
            span {
                width: 20%;
            }

            .photo {
                width: 100%;
            }
        }

        .overlay {
            position: fixed;
            display: none;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
        }

        .CircularProgressbar {
            .CircularProgressbar-path {
                stroke: rgba(57, 109, 124, 1);
                stroke-linecap: round;
                transition: stroke-dashoffset 0.5s ease 0s;
            }

            .CircularProgressbar-trail {
                stroke: white;
            }
        }

        .circle-progress {
            width: 40px;
            height: 40px;
            border: 1px solid rgba(243, 244, 246, 1);
            border-radius: 50%;
        }
    }

    .session-completed {
        padding: 5px 15px;
        width: fit-content;
        height: fit-content;
        border-radius: 0px 0px 10px 10px;
    }
}