.header {
    width: 100% !important;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.20);
    .logo-container {
        position: relative;
        width: 130px;
        height: 56px;
    }

    .logo-container:after {
        content: '';
        height: 20px;
        width: 1px;

        position: absolute;
        right: 0;
        top: 18px;

        background-color: rgba(57, 109, 124, 1);
    }
}

@media screen and (max-width: 640px) {
    .header {
        .user-profile {
            display: none;
        }

        align-items: unset !important;

        >div {
            display: block;
        }

        box-shadow: unset !important;
    }
}