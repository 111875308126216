.org-detail {

    // overflow: hidden;
    .org-top {
        height: 160px;
        min-height: 160px;
        padding: 0 15px;
    }

    .banner-context-org {
        z-index: 1;
    }

    .tab-button {
        .tab-button {
            padding-left: 25px !important;

            button[aria-selected="true"] {
                border-color: rgba(12, 192, 223, 1);
                color: rgba(12, 192, 223, 1)
            }
        }

        >div:nth-child(2) {
            height: 100%;

            >div:nth-child(2) {
                height: 100%;
            }
        }
    }

    .group-avatar {
        .bg-gray-300 {
            background: #003333;
        }
    }

    .organization {
        &-item {
            background: #F3F4F6;
        }

        &-left {
            width: 60px;
            height: 60px;
            background: rgba(0, 51, 51, 0.1);

        }
    }

    @media screen and (min-width: 640px) {
        .action-table {
            position: absolute;
            top: 0;
            right: 0;
            margin-top: -85px;
            z-index: 1;
            width: calc(100% - 330px);
        }

        .action-search {
            max-width: calc(100% - 155px);
        }

    }

    @media screen and (max-width: 640px) {
        .org-top {
            text-overflow: ellipsis;
        }

        .banner-image {
            width: 40px;
            height: 40px;
        }

        .banner-context-org {
            padding-left: 15px;
            font-size: 12px;

            .title {
                font-size: 20px;
            }

            .context {
                display: block;
            }
        }

        .description {
            .title {
                font-size: 18px;
            }

            .content {
                font-size: 14px;
            }

        }

        .action {
            margin-top: 15px;
            padding: 15px;

            .title {
                font-size: 18px;
            }

            .content {
                font-size: 14px;
            }
        }

        .avatar {
            width: 90px;
            height: 90px;
        }

        .banner {
            padding-left: 15px;
            padding-right: 15px;
        }

        .row {
            display: block;
        }

        .row-item {
            margin-top: 0;
            padding-top: 2px;
            padding-bottom: 3px;
        }

        .row .left {
            padding-left: 30px;
        }

        .box {
            padding: 15px;
            margin-bottom: 15px;
        }

        .tab-content {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .avatar:hover .icon {
        opacity: 1;
    }

    .datepicker {
        svg {
            color: #6B7280;
            fill: #6B7280;
        }
    }
    input {
        border-radius: 8px !important;
        background-color: transparent;
        border-color: #E8E8E8;
    }
}